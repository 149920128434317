import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CiClock2, CiCalendar } from "react-icons/ci";
import BookingService from "../services/booking.service";
import ProductService from "../services/product.service";
import CategoryService from "../services/category.service";
import UserService from "../services/user.service";
import Select from 'react-select';
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import { formatTimeTo24Hour } from "../shared/utils/string";
import { Map } from "../components/google_map";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        height: '680px',
    },
};


const customSelectStyles = {
    control: (styles) => ({
        ...styles,
        width: '100%',
        height: '60px',
        padding: '0 15px',
        fontSize: '14px',
        lineHeight: '60px',
        color: '#393D44',
        border: '0',
        borderRadius: '20px',
        background: '#ffffff',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
};

const CreateTaskManagerModal = ({ axiosPrivate }) => {
    Modal.setAppElement('#root');
    // const axiosPrivate = useAxiosPrivate2();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

    const [value, setValue] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [bookings, setBookings] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const [flypros, setFlypros] = useState([]);
    const [customers, setCustomers] = useState([]);
    const notify = useNotifications();
    const { auth } = useAuth();

    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [coordinates, setCoordinate] = useState({
        lat: 46.356,
        lng: 6.5641
    });

    const [formData, setFormData] = useState({
        category_id: "",
        subcategory_id: "",
        product_id: "",
        parent_category_id: "",
        duration: "",
        date: "",
        date_only: "",
        timeBooking: "",
        description: "",
        message: "",
        postcode: "",
        therapist_id: [],
        client_id: "",
        address_id: "",
        // promoCode: "",
        status: "CREATED",
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        country: "Switzerland",
        country_code: "CH",
        latitude: null,
        longitude: null
    });

    const resetForm = () => {
        setFormData({
            category_id: "",
            subcategory_id: "",
            product_id: "",
            duration: "",
            parent_category_id: "",
            date: "",
            date_only: "",
            timeBooking: "",
            message: "",
            address_id: "",
            description: "",
            postcode: "",
            therapist_id: [],
            client_id: "",
            // promoCode: "",
            status: "CREATED",
            zip_code: "",
            state: "",
            state_code: "",
            street: "",
            street_number: "",
            city: "",
            country: "Switzerland",
            country_code: "CH",
            latitude: null,
            longitude: null,
            hotel_id: auth.user.hotel[0]?.id,
        });
        setSelectedFlyproOption([]);
        setSubCategories([]);
        setProductList([]);
        setCoordinate({
            lat: 46.356,
            lng: 6.5641
        })
        setIsAddressUpdate(false);
    };

    const handleTimeChange = (newValue) => {
        setSelectedTime(newValue);
    };

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    const handleOkClick = () => {
        setValue(selectedTime);
        const timeString = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        setFormData({
            ...formData,
            timeBooking: timeString,
        });
        setTimePickerIsOpen(false);
    };

    const handleCancelClick = () => {
        setTimePickerIsOpen(false);
    };

    const openDatePicker = () => {
        setDatePickerIsOpen(true);
    };

    const closeDatePicker = () => {
        setDatePickerIsOpen(false);
    };

    const handleDatePickerOk = () => {
        const dateString = value.toLocaleDateString('en-CA');
        setFormData({
            ...formData,
            date_only: dateString,
        });
        closeDatePicker();
    };

    // const fetchBookings = async () => {
    //   try {
    //     const response = await BookingService.index({ with: 'address,client,product,creator,therapist' }, axiosPrivate);
    //     setBookings(response);
    //   } catch (error) {

    //   }
    // };

    // useEffect(() => {
    //   fetchBookings();
    // }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        let state = cantons.cantons.find(
            (canton) => canton.name === formData.state
        );

        const [start, end] = state?.zip_code_range?.split("-")?.map(Number) || [0, 0];
        if (formData.state != null && (formData.zip_code < start || formData.zip_code > end)) {
            notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
            setFormData({
                ...formData,
                zip_code: '',
            });
            return;
        }

        try {
            await fetchCoordinates();
        } catch (error) {

        }



        formData.address_id = auth.user.hotel[0]?.address_id;
        formData.street = auth.user?.hotel?.[0]?.address?.street || '';
        formData.city = auth.user?.hotel?.[0]?.address?.city || '';
        formData.zip_code = auth.user?.hotel?.[0]?.address?.zip_code || '';
        formData.state = auth.user?.hotel?.[0]?.address?.state || '';
        formData.latitude = auth.user?.hotel?.[0]?.address?.latitude || null;
        formData.longitude = auth.user?.hotel?.[0]?.address?.longitude || null;
        formData.country = "Switzerland";
        formData.country_code = "CH";
        const time = formatTimeTo24Hour(formData.timeBooking);
        const combinedDateTime = `${formData.date} ${time}`;
        formData.date = combinedDateTime;
        formData.start_before = combinedDateTime;
        formData.hotel_id = auth.user?.hotel[0]?.id;
        formData.client_id = auth.user?.id;

        console.log('primary_address_id : ', auth.user.primary_address_id)
        console.log('form data address_id : ', formData)

        try {
            const response = await BookingService.store(formData, axiosPrivate);
            if (response.data) {
                closeModal();
            }
        } catch (error) {

        }
    };

    const fetchAvailableTherapists = async () => {
        try {
            console.log({ date: formData.date, time: formData.timeBooking, latitude: formData.latitude, longitude: formData.longitude })
            console.log('hotel : ', auth.user.hotel[0])
            console.log('form data : ', formData)
            const response = await UserService.indexByRole(null, 'ROLE_FLYPRO', axiosPrivate);
            console.log(response)
            setFlypros(response);
        } catch (error) {

        }
    }

    async function openModal(filters) {
        resetForm();
        const updatedFilters = {
            ...filters,
            with: "media,category.parent,domain,sub_products,parent,hotel",
            hotel_id: auth.user?.hotel[0]?.id
        };
        const categories = await CategoryService.index({ parent_id: null, status: 'ACTIVE' }, axiosPrivate);
        const customer = await UserService.indexByRole({ with: 'primary_address' }, 'ROLE_CLIENT', axiosPrivate);
        const productResponse = await ProductService.index(updatedFilters, axiosPrivate);
        console.log({ productResponse })
        setCategories(categories);
        setCustomers(customer);
        setProductList(productResponse);

        setFormData((prev) => ({
            ...prev,
            hotel_id: auth.user?.hotel?.[0]?.id,
        }));

        setIsOpen(true);
    };

    const closeModal = () => {
        resetForm();
        setIsOpen(false);
    };

    // Handle product change
    const handleProductSelectChange = (selected) => {
        setFormData(prev => ({
            ...prev,
            product_id: selected ? selected.value : null,
            duration: selected ? selected.duration : ''

        }));
    };

    // Handle flypro change
    const handleFlyproChange = (selected) => {
        console.log({ selected })
        if (selected.length > 2) {
            return;
        }
        if (selected?.length) {
            setSelectedFlyproOption(selected);
            setFormData((prev) => ({
                ...prev,
                therapist_id: selected.map((therapist) => therapist.value)
            }));
        } else {
            setSelectedFlyproOption([]);

            setFormData((prev) => ({
                ...prev,
                therapist_id: []
            }));
        }
    };

    // Create options for Category, Subcategory, Product, Flypro
    const categoryOptions = categories?.length > 0 ? categories.map(row => ({ value: row.id, label: row.title })) : [];
    const subcategoryOptions = subCategories?.length > 0 ? subCategories.map(row => ({ value: row.id, label: row.title })) : [];
    const productOptions = productList?.length > 0 ? productList.map(row => ({ value: row.id, label: row.name, duration: row.duration })) : [];
    const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];
    const customerOptions = customers?.length > 0 ? customers.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

    // Determine currently selected options
    const selectedCategoryOption = categoryOptions.find(o => o.value === formData.category_id) || null;
    const selectedSubcategoryOption = subcategoryOptions.find(o => o.value === formData.subcategory_id) || null;
    const selectedProductOption = productOptions.find(o => o.value === formData.product_id) || null;
    // const selectedFlyproOption = flyproOptions.find(option => formData.therapist_id.includes(option.value)) || null;
    const selectedCustomerOption = customerOptions.find(option => option.value === formData.client_id) || null;
    const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
    // Fonction pour mettre à jour les valeurs du formulaire

    const handleChange = async (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

    };

    const fetchCoordinates = async () => {
        const { street_number, street, city, state, zip_code } = formData;
        const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        address,
                        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    },
                }
            );

            if (response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                setFormData({
                    ...formData,
                    latitude: location.lat,
                    longitude: location.lng,
                });
                setCoordinate(location)
            } else {
                console.error('No results found for the given address.');
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };
    const [selectedState, setSelectedState] = useState(null);
    const [showMap, setShowMap] = useState(false);

    const handleShowMap = async () => {
        await fetchCoordinates();
        setShowMap(true);
    }

    const loadTherapist = async () => {
        await fetchAvailableTherapists();
    }

    const handleCoordinateChange = (coordinates) => {
        setFormData({
            ...formData,
            longitude: coordinates[0]?.lng,
            latitude: coordinates[0]?.lat,
        })
        setCoordinate({
            lat: coordinates[0]?.lng,
            lng: coordinates[0]?.lat,
        })
    }

    const handleStateChange = (data) => {
        let selected = cantons.cantons.find(
            (canton) => canton.name === data?.value
        );
        document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');
        setFormData({
            ...formData, zip_code: ''
        });
        setFormData({
            ...formData,
            state: data?.value,
        });
        setSelectedState(data);
    };

    return (
        <>
            <button onClick={openModal} className="btn-add-client">
                Create a task
            </button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add a Booking"
            >
                <button className="close-button" onClick={closeModal}>
                    <span className="close-icon">✖</span>
                </button>

                <h2>Create a task</h2>
                <p>If you need more info, please check out</p>
                <div className="form-inscription">
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-text">
                            <label>Product*</label>
                            <Select
                                value={selectedProductOption}
                                onChange={handleProductSelectChange}
                                options={productList?.map(product => ({
                                    value: product.id,
                                    label: product.name,
                                    duration: product.duration
                                }))}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a product"
                                noOptionsMessage={() => "No products available"}
                            />

                        </div>

                        <div className="form-text">
                            <label>Duration</label>
                            <input type="number" name="duration" readOnly value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} />
                        </div>

                        <div className="dates no-position">
                            <div className="form-text dateOfTheBookingParent topMarg">
                                <label>Date of the booking</label>
                                <input
                                    type="date"
                                    name="date_only"
                                    value={formData.date_only}
                                    onChange={(e) => setFormData({ ...formData, date_only: e.target.value })}
                                />
                            </div>

                            <div className="form-text timeOfTheBookingParent topMarg">
                                <label>Time of the booking</label>
                                <input
                                    type="time"
                                    name="timeBooking"
                                    value={formData.timeBooking}
                                    onChange={(e) => setFormData({ ...formData, timeBooking: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="form-text topMargArea">
                            <label>Description</label>
                            <textarea className="formTextArea" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                        </div>

                        <div className="form-text topMargArea">
                            <label>Comment</label>
                            <textarea className="formTextArea" name="message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                        </div>

                        <div className="form-text" onClick={loadTherapist}>
                            <label>Flypro</label>
                            <Select
                                value={selectedFlyproOption}
                                onChange={handleFlyproChange}
                                options={flyproOptions}
                                styles={customSelectStyles}
                                isClearable
                                required
                                isSearchable
                                isMulti
                                placeholder="Select a Flypro"
                                noOptionsMessage={() => "No Flypros available"}
                            />
                        </div>

                        {/* <div className="form-text">
          <label>Promo CODE</label>
          <input type="text" name="promoCode" value={formData.promoCode} onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })} />
        </div> */}

                        {formData.latitude && showMap && (<Map
                            coordinates={[coordinates]}
                            mode="edit"
                            containerStyle={{ width: '100%', height: '400px' }}
                            mapOptions={{
                                disableDefaultUI: true,
                                zoomControl: true,
                                mapTypeControl: false,
                                fullscreenControl: true,
                                streetViewControl: false,
                            }}
                            onCoordinateChange={handleCoordinateChange}
                        />)}
                        <button type="submit" className="btn-submit">
                            Créer la tâche
                        </button>
                    </form>
                </div>
            </Modal>
        </>
    )
};

export default CreateTaskManagerModal;
import React, { useState, useEffect, Fragment, useRef } from "react";
import MenuHeader from "../MenuHeader";
import Modal from "react-modal";
import BookingService from "../../services/booking.service";
import styles from './ListTable.module.css';
import { addressFormat, asTitle, castDate } from "../../shared/utils/string";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import CreateTaskModal from "./../CreateBookingModal";
import CreateTaskManagerModal from "./../CreateBookingManagerModal";

Modal.setAppElement("#root"); // Ensure this matches your root element

const Booking = ({ showHeader = true }) => {

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden auto",
      scrollbarWidth: "none",
      height: "650px",
    },
  };

  const location = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const [bookings, setBookings] = useState([]);
  const [filterData, setFilterData] = useState({
    search: "",
    search_by: "id",
    operator: "contains",
    status: "",
  });

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [filters, setFilters] = useState({
    with: `address,client,product.category,creator,therapists,id`,
    page: currentPage,
    per_page: perPage,
    id: id ? `in:${id}` : "",
  });

  const [sortField, setSortField] = useState("id");
  const [sortDirection, setSortDirection] = useState(true);
  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const timeoutRef = useRef(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const { auth } = useAuth();
  const userRoles = auth?.roles;


  const addIdToWith = (id) => {
    setFilters((prevFilters) => {
      // Créer une copie des filtres existants
      const updatedFilters = { ...prevFilters };


      updatedFilters['id'] = `in:${id}`;

      console.log(updatedFilters);  // Affiche l'objet de filtre mis à jour

      return updatedFilters;
    });
  };

  // Fetch bookings on mount and on filter changes
  const fetchBookings = async () => {
    try {
      const roleBasedFilters = { ...filters };

      if (!userRoles?.some(role => role.name === "ROLE_ADMIN")) {
        const response = await BookingService.index({ ...filters, created_by: auth.user.id },
          axiosPrivate
        );
        setBookings(response.data);
        console.log(response.data)
        setTotal(response.total);
        setLastPage(response.last_page);

        if (response.last_page < currentPage) {
          setFilters({ ...filters, page: response.last_page });
          setCurrentPage(response.last_page);
        }
      } else {

        const response = await BookingService.index(roleBasedFilters, axiosPrivate);
        console.log("new", roleBasedFilters)
        console.log("old", filters)
        setBookings(response.data);
        setTotal(response.total);
        setLastPage(response.last_page);

        if (response.last_page < currentPage) {
          setFilters({ ...filters, page: response.last_page });
          setCurrentPage(response.last_page);
        }
      }

    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  useEffect(() => {

    fetchBookings();

  }, [currentPage, perPage, filters, userRoles]);

  const handleFilterChange = async (e) => {
    const target = e.target;
    if (target.name === 'search_by') {
      const search = document.querySelector('input[name="search"]');
      search.setAttribute('placeholder', `Search By ${target.options[target.selectedIndex].innerHTML}`);
      filterData.search = '';
      if (target.value === 'date' || target.value === 'start_before') {
        search.setAttribute('type', 'date')
      } else {
        search.setAttribute('type', 'text')
      }
      if (target.value === "date_range") {
        setDateRange({ start_date: "", end_date: "" });
      }
    }
    else if (target.name === "start_date" || target.name === "end_date") {
      setDateRange({ ...dateRange, [target.name]: target.value });
    } else if (target.name === 'search' && target.value === '') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      delete filters.id;
      delete filters.duration;
      delete filters.start_before;
      delete filters.date;
      if (filterData.status != '') {
        filters.status = filterData.status
      }
      await fetchBookings();
    } else if (target.name === 'status') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      if (target.value != '') {
        filters.status = target.value
      } else {
        delete filters.status;
      }
      await fetchBookings();
    }
    setFilterData({
      ...filterData,
      [target.name]: target.value
    });

  }


  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    if (filterData.search_by === "date_range") {
      if (dateRange.start_date && dateRange.end_date) {
        setFilters({
          ...filters,
          start_before: `between:${dateRange.start_date},${dateRange.end_date}`,
          page: 1,
        });
      }


    } else if (filterData.search) {
      filters[filterData.search_by] = `${filterData.operator}:${filterData.search}`;
    }
    if (filterData.status) {
      filters.status = filterData.status;
    }

    // Fetch filtered data
    await fetchBookings();
  };

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);

  }

  const handleSort = async (event) => {
    const selectedColumn = event.target.id;

    let newSortDirection = true;
    if (sortField === selectedColumn) {
      newSortDirection = !sortDirection; // Toggle direction for the same column
    }

    setSortField(selectedColumn);
    setSortDirection(newSortDirection);

    const sortParam = `${newSortDirection ? '' : '-'}${selectedColumn}`;
    const updatedFilters = { ...filters, sort: sortParam, page: 1 };

    setFilters(updatedFilters);
  };

  const openDetailsModal = (booking) => {
    setSelectedBooking(booking);
    setDetailsModalIsOpen(true);
  };

  const closeDetailsModal = () => {
    setSelectedBooking(null);
    setDetailsModalIsOpen(false);
  };

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleDelete = async (id) => {
    try {
      const response = await BookingService.delete(id, axiosPrivate);
      fetchBookings();
    } catch (error) {

    }
  };

  const Table = ({ data }) => (
    <div className={styles.tableContainer}>
      <table className={styles.customTable}>
        <thead className={styles.tableHeader}>
          {/* --- ADMIN HEADER --- */}
          {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
            <tr>
              <th id="id" className="sort-header" onClick={handleSort}>ORDER ID</th>
              <th id="product" className="sort-header" onClick={handleSort}>Product</th>
              <th>DESCRIPTION</th>
              <th>FLYING DETAILS</th>
              <th>ADDRESS</th>
              <th>CREATED BY</th>
              <th id="start_before" className="sort-header" onClick={handleSort}>START BEFORE</th>
              <th>COMPLETE BEFORE</th>
              <th>Action</th>
            </tr>
          )}

          {/* --- HOTEL MANAGER HEADER --- */}
          {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
            <tr>
              <th id="id" className="sort-header" onClick={handleSort}>ORDER ID</th>
              <th>TEAM NAME</th>
              <th>TAGS</th>
              <th id="product" className="sort-header" onClick={handleSort}>Product</th>
              <th>DESCRIPTION</th>
              <th>FLYING DETAILS</th>
              <th id="client" className="sort-header" onClick={handleSort}>NAME</th>
              <th>ADDRESS</th>
              <th>CREATED BY</th>
              <th id="start_before" className="sort-header" onClick={handleSort}>START BEFORE</th>
              <th>COMPLETE BEFORE</th>
              <th>REVIEW / RATING</th>
              <th id="status" className="sort-header" onClick={handleSort}>TASK STATUS</th>
              <th>Action</th>
            </tr>
          )}
        </thead>

        {/* --- ADMIN BODY --- */}
        {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} className={styles.tableRow}>
                <td className={`${styles.tableCell} ${styles.taskColor}`}>{item.id}</td>
                <td className={styles.tableCell}>{item.product?.name}</td>
                <td className={styles.tableCell}>{item.description}</td>
                <td className={styles.tableCell} style={{ whiteSpace: "pre-line" }}>
                  {item.therapists?.map(
                    (therapist) =>
                      `id:${therapist.id}\nname:${therapist.extra?.full_name}\n${therapist.email}\n`
                  )}
                </td>
                <td className={styles.tableCell}>{addressFormat(item.address)}</td>
                <td className={styles.tableCell}>{item.creator?.extra.full_name}</td>
                <td className={styles.tableCell}>{castDate(item.start_before)}</td>
                <td className={styles.tableCell}>
                  {castDate(
                    new Date(
                      new Date(item.start_before).getTime() + item.duration * 60 * 1000
                    )
                  )}
                </td>
                <td>
                  <div
                    className="edit"
                    onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                    style={{ position: "relative", marginLeft: "25px" }}
                    onMouseLeave={handleEditMouseLeave}
                  >
                    &#8942;
                    {hoveredRow === index && (
                      <div className="dropdown-menu">
                        <ul>
                          <li onClick={() => openDetailsModal(item)}>View Details</li>
                          <li className="danger" onClick={() => handleDelete(item.id)}>
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}

        {/* --- HOTEL MANAGER BODY --- */}
        {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
          <tbody>
            {data?.map((item, index) => (
              <tr key={index} className={styles.tableRow}>
                <td className={`${styles.tableCell} ${styles.taskColor}`}>{item.id}</td>
                <td className={styles.tableCell}>{item.team_name || "-"}</td>
                <td className={styles.tableCell}>{item.tags || "-"}</td>
                <td className={styles.tableCell}>{item.product?.name}</td>
                <td className={styles.tableCell}>{item.description}</td>
                <td className={styles.tableCell} style={{ whiteSpace: "pre-line" }}>
                  {item.therapists?.map(
                    (therapist) =>
                      `id:${therapist.id}\nname:${therapist.extra?.full_name}\n${therapist.email}\n`
                  )}
                </td>
                <td className={styles.tableCell}>{item.client?.extra?.full_name}</td>
                <td className={styles.tableCell}>{addressFormat(item.address)}</td>
                <td className={styles.tableCell}>{item.creator?.extra.full_name}</td>
                <td className={styles.tableCell}>{castDate(item.start_before)}</td>
                <td className={styles.tableCell}>
                  {castDate(
                    new Date(
                      new Date(item.start_before).getTime() + item.duration * 60 * 1000
                    )
                  )}
                </td>
                <td className={styles.tableCell}>{/* REVIEW / RATING column? */}-</td>
                <td className={styles.tableCell}>{asTitle(item.status)}</td>
                <td>
                  <div
                    className="edit"
                    onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                    style={{ position: "relative", marginLeft: "25px" }}
                    onMouseLeave={handleEditMouseLeave}
                  >
                    &#8942;
                    {hoveredRow === index && (
                      <div className="dropdown-menu">
                        <ul>
                          <li onClick={() => openDetailsModal(item)}>View Details</li>
                          <li className="danger" onClick={() => handleDelete(item.id)}>
                            Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}

        <tfoot >
          <tr>
            <td colSpan={13}>
              <div className="paginator">
                <select value={perPage} onChange={handlePerPageChange} className="per-page">
                  <option value={1}>1</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={250}>250</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
                <div className="total">Total Records: {total}</div>
                <div className="pagination">
                  <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                  <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                  <span>{currentPage} / {lastPage}</span>
                  <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                  <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );

  return (
    <Fragment>
      {showHeader && <MenuHeader />}

      <div className="bloc-search">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Task ID"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>

            {filterData.search_by === "date_range" && (
              <div className="text-search input-350-width">
                <input
                  type="date"
                  name="start_date"
                  value={dateRange.start_date}
                  onChange={handleFilterChange}
                  className="form-text"
                />
                <input
                  type="date"
                  name="end_date"
                  value={dateRange.end_date}
                  onChange={handleFilterChange}
                  className="form-text"
                />
              </div>
            )}

            <select
              name="search_by"
              value={filterData.search_by}
              onChange={handleFilterChange}
            >
              <option value="id">Task ID</option>
              <option value="duration">Duration</option>
              <option value="start_before">Start Before</option>
              <option value="date">Date</option>
              <option value="date_range">Date Range</option>
            </select>

            <select
              name="operator"
              value={filterData.operator}
              onChange={handleFilterChange}
            >
              <option value="contains">Contains</option>
              <option value="starts">Starts With</option>
              <option value="ends">Ends With</option>
              <option value="eq">Equal</option>
              <option value="!eq">Not Equal</option>
              <option value="in">In</option>
            </select>

            <select
              name="status"
              value={filterData.status}
              onChange={handleFilterChange}
            >
              <option value="">All Statuses</option>
              <option value="CREATED">Created</option>
              <option value="ACKNOWLEDGED">Acknowledged</option>
              <option value="PENDING_ACKNOWLEDGEMENT">Pending Acknowledgement</option>
              <option value="RECLINED">Reclinded</option>
              <option value="STARTED">Started</option>
              <option value="REACHED">Reached</option>
              <option value="COMPLETED">Completed</option>
              <option value="FAILED">Failed</option>
            </select>
          </form>

          {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') ? (
            <CreateTaskManagerModal axiosPrivate={axiosPrivate} />
          ) : (
            <CreateTaskModal axiosPrivate={axiosPrivate} />
          )}

        </div>
      </div>

      <Modal
        isOpen={detailsModalIsOpen}
        onRequestClose={closeDetailsModal}
        style={customStyles}
        contentLabel="Booking Details"
      >
        <button className="close-button" onClick={closeDetailsModal}>
          <span className="close-icon">✖</span>
        </button>
        {selectedBooking && (

          <div className="form-inscription mrgBottom">
            <h2>Booking Details</h2>
            <p>If you need more info, please check out</p>
            <form>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Order ID:</label>
                <input type="text" name="id" value={selectedBooking.id || '-'} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Team Name:</label>
                <input type="text" name="team_name" value={selectedBooking.team_name || "-"} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Tags:</label>
                <input type="text" name="tags" value={selectedBooking.tags || "-"} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Description:</label>
                <input type="text" name="description" value={selectedBooking.description} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Flying Details:</label>
                <input type="text" name="flying_details" value={selectedBooking.therapists?.map(
                  (therapist) =>
                    `ID: ${therapist.id}\nName: ${therapist.extra?.full_name}\nEmail: ${therapist.email}\n`
                )} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Address:</label>
                <input type="text" name="address" value={addressFormat(selectedBooking.address)} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Created By:</label>
                <input type="text" name="created_by" value={selectedBooking.creator?.extra?.full_name} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Start Before:</label>
                <input type="text" name="start_before" value={castDate(selectedBooking.start_before)} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Complete Before:</label>
                <input type="text" name="complete_before" value={castDate(new Date(new Date(selectedBooking.start_before).getTime() + selectedBooking.duration * 60 * 1000))} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Status:</label>
                <input type="text" name="status" value={asTitle(selectedBooking.status)} readOnly />
              </div>
            </form>
          </div>
        )}
      </Modal>


      <div className={styles.scrollableTableContainer}>
        <Table data={bookings} />
      </div>
    </Fragment>
  );
};

export default Booking;

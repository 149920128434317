// ChooseLocation.js
import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useNotifications } from '../context/NotificationProvider';
import UserService from "../services/user.service";
import ZoneService from "../services/zone.service";
import CategoryService from "../services/category.service";
import cantons from "../shared/data/cantons.json";
import { asTitle, castDate } from '../shared/utils/string';
import { Map } from "./google_map";
import axios from "../api/axios";
import Select from 'react-select';
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import imageFile from "../assets/images/upload-file.svg";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "95vh",
    scrollbarWidth: "none",
  },
};
const Flypro = () => {

  const axiosMultiPart = useAxiosMultiPart();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenManage, setIsOpenManage] = React.useState(false);
  const notify = useNotifications();

  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const [zones, setZones] = useState([]);
  const [managers, setManagers] = useState([]);
  const [categories, setCategories] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [formData, setFormData] = useState({
    id: null, //
    civility: null,
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    zones: [],
    managers: [],
    country: "Switzerland",
    country_code: "CH",
    role: "ROLE_FLYPRO",
    latitude: null,
    longitude: null,
    is_asca: false,
    is_rme: false,
    categories: [],
    tags: {
      bio: "",
      experience: [],
      language: [],
    },
    agent_type: null,
  });
  const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);

  const fetchCategories = async () => {
    try {
      const response = await CategoryService.index(
        { parent_id: '!eq:null', status: "ACTIVE" },
        axiosPrivate
      );

      setCategories(response);
    } catch (error) {
      console.error("Error fetching parent categories:", error);
    }
  };


  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: 'AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk',
  //   libraries,
  // });
  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };

  const [filters, setFilters] = useState({
    with: 'primary_address,zones,managers,media,categories',
    page: currentPage,
    per_page: perPage,
    sort: 'firstname'
  });
  const [filterData, setFilterData] = useState({
    search: "",
    search_by: "name",
    operator: "contains",
    status: "",
    tags: null
  });

  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [coordinates, setCoordinate] = useState({
    lat: 46.356,
    lng: 6.5641
  });
  const [availableZones, setAvailableZones] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const timeoutRef = useRef(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [showMap, setShowMap] = useState(false);

  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '60px',
      padding: '0 15px',
      fontSize: '14px',
      lineHeight: '60px',
      color: '#393D44',
      border: '0',
      borderRadius: '20px',
      background: '#ffffff',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    // placeholder: (base) => ({
    //   ...base,
    //   marginTop: '-10px',
    // }),
    // dropdownIndicator: (base) => ({
    //   ...base,
    //   marginTop: '-10px',
    // }),
  };

  const [selectedState, setSelectedState] = useState(null);
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [modalType, setModalType] = useState(null); // To track the type of modal
  const [selectedRow, setSelectedRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  /* ========================================== */

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk",
    libraries: ['places'],
  });

  const handleStateChange = (data) => {
    setIsAddressUpdate(true);
    let selected = cantons.cantons.find(
      (canton) => canton.name === data?.value
    );
    document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');
    setFormData({
      ...formData, zip_code: ''
    });
    setFormData({
      ...formData,
      state: data?.value,
    });
    setSelectedState(data);
  };

  const handleZonesChange = (selected) => {
    if (selected?.length) {
      setSelectedZones(selected);
      setFormData((prev) => ({
        ...prev,
        zones: selected.map((zone) => zone.value)
      }));
    } else {
      setSelectedZones([]);

      setFormData((prev) => ({
        ...prev,
        zones: []
      }));
    }
  };

  const handleManagersChange = (selected) => {
    if (selected?.length) {
      setSelectedManagers(selected);
      setFormData((prev) => ({
        ...prev,
        managers: selected.map((managers) => managers.value)
      }));
    } else {
      setSelectedManagers([]);

      setFormData((prev) => ({
        ...prev,
        managers: []
      }));
    }

  };

  const handleCategoriesChange = (selected) => {
    if (selected?.length) {
      setSelectedCategories(selected);
      setFormData((prev) => ({
        ...prev,
        categories: selected.map((categories) => categories.value)
      }));
    } else {
      setSelectedCategories([]);

      setFormData((prev) => ({
        ...prev,
        categories: []
      }));
    }

  };

  const handleChange = (e) => {
    if (formData.id && e.target.name === 'zip_code' || e.target.name === 'street' || e.target.name === 'street_number' || e.target.name === 'city') {
      setIsAddressUpdate(true);
    }

    // Handle file input
    if (e.target.name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  };

  const handlePlaceSelect = (place) => {
    const zipCode = place.address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;
    const addressComponents = place.address_components;
    const updatedAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: 'Switzerland',
      country_code: 'CH',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    if (zipCode) {
      setFormData((prev) => ({ ...prev, zip_code: zipCode }));
      setIsZipCodeVisible(false);
    } else {
      setIsZipCodeVisible(true);
    }

    addressComponents.forEach(component => {
      const type = component.types[0];
      switch (type) {
        case 'street_number':
          updatedAddress.street_number = component.long_name;
          break;
        case 'route':
          updatedAddress.street = component.long_name;
          break;
        case 'locality':
          updatedAddress.city = component.long_name;
          break;
        case 'postal_code':
          updatedAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          updatedAddress.state = component.long_name;
          updatedAddress.state_code = component.short_name;
          break;
      }
    });

    setFormData((prev) => ({
      ...prev,
      street: updatedAddress.street,
      street_number: updatedAddress.street_number,
      city: updatedAddress.city,
      state: updatedAddress.state,
      state_code: updatedAddress.state_code,
      zip_code: updatedAddress.zip_code,
      latitude: updatedAddress.lat,
      longitude: updatedAddress.lng,
    }));
    setShowMap(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );


    if (isAddressUpdate || (formData.zip_code != '' && formData.latitude === null)) {
      try {
        await fetchCoordinates();
        setIsAddressUpdate(false);
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
      notify.info('Please validate the current position');
      return;
    }

    formData.role = 'ROLE_FLYPRO';
    formData.country = "Switzerland";
    formData.country_code = "CH";

    const formDataToSend = new FormData();

    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      formDataToSend.append("imageFile", file);
    }

    try {
      const response = await (formData.id ? UserService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate) : UserService.store(file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate));
      if (response.data) {
        closeModal();
        fetchCustomers();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate);
      setCustomers(response.data);
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...filters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
    } catch (error) {
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await UserService.delete(id, axiosPrivate);
      fetchCustomers();
    } catch (error) {

    }
  };

  const fetchZones = async () => {
    try {
      const response = await ZoneService.index(null, axiosPrivate);

      setZones(response);

    } catch (error) {
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await UserService.indexByRole(null, 'ROLE_MANAGER', axiosPrivate);

      setManagers(response);

    } catch (error) {
    }
  };

  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {
    fetchCategories();
    fetchZones();
    fetchManagers();
    fetchCustomers();
  }, [currentPage, perPage]); // Recharger les données à chaque changement de page

  function openModal(flypro) {
    if (flypro && flypro.id) {
      let phone = flypro.phone;
      if (phone && phone.startsWith("0")) {
        phone = "+41" + phone.substring(1);
      }

      setFormData({
        ...formData,
        ...flypro,
        ...{
          primary_address_id: flypro.primary_address?.id,
          zip_code: flypro.primary_address?.zip_code,
          state: flypro.primary_address?.state,
          state_code: flypro.primary_address?.state_code,
          street: flypro.primary_address?.street,
          street_number: flypro.primary_address?.street_number,
          city: flypro.primary_address?.city,
          country: flypro.primary_address?.country,
          country_code: flypro.primary_address?.country_code,
          longitude: flypro.primary_address?.longitude,
          latitude: flypro.primary_address?.latitude,
          phone,
          zones: flypro.zones.map(zone => zone.id),
          managers: flypro.managers.map(manager => manager.id),
          categories: flypro.categories?.map(category => category.id),
          tags: {
            bio: flypro.tags?.bio || "",
            experience: flypro.tags?.experience || [],
            language: flypro.tags?.language || [],
          },
          agent_type: flypro.agent_type || null,
        },
      });
      if (flypro.primary_address?.state) {
        setSelectedState({
          value: flypro.primary_address?.state,
          label: flypro.primary_address?.state
        })
      }
      if (flypro.primary_address?.longitude) {
        setCoordinate({
          lat: flypro.primary_address?.latitude,
          lng: flypro.primary_address?.longitude,
        })
      }

      setSelectedZones(
        flypro.zones?.map((zone) => ({ value: zone.id, label: zone.name })) || []
      );

      setSelectedManagers(
        flypro.managers?.map((manager) => ({
          value: manager.id,
          label: `${manager.extra.full_name} (${manager.email})`,
        })) || []
      );

      setSelectedCategories(
        flypro.categories?.map((category) => ({
          value: category.id,
          label: category.title,
        })) || []
      );
    } else {
      resetModalStates();
    }
    setShowMap(false);
    setIsOpen(true);
  }


  function openModalManage() {
    setIsOpenManage(true);
  }

  function afterOpenModal() {

  }

  function afterOpenModalManage() {

  }

  function closeModalManage() {
    setIsOpenManage(false);
    resetModalStates();
    fetchCustomers();
  }

  function closeModal() {
    setFormData({
      civility: null,
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      zip_code: "",
      state: "",
      state_code: "",
      street: "",
      street_number: "",
      city: "",
      zones: [],
      managers: [],
      categories: [],
      country: "Switzerland",
      country_code: "CH",
      role: "ROLE_FLYPRO",
      latitude: null,
      longitude: null,
      is_asca: false,
      is_rme: false,
      tags: {
        bio: "",
        experience: [],
        language: [],
      },
      agent_type: null,
    });
    setCoordinate({
      lat: 46.356,
      lng: 6.5641
    })
    setIsAddressUpdate(false);
    setIsOpen(false);
    setSelectedState(null);
    setIsOpen(false);
  }

  const fetchCoordinates = async () => {
    const { street_number, street, city, state, zip_code } = formData;
    const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          },
        }
      );

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setFormData({
          ...formData,
          latitude: location.lat,
          longitude: location.lng,
        });
        setCoordinate({
          lat: location.lat,
          lng: location.lng
        });
      } else {
        console.error('No results found for the given address.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleCoordinateChange = (coordinates) => {
    setFormData({
      ...formData,
      longitude: coordinates[0]?.lng,
      latitude: coordinates[0]?.lat,
    })
    setCoordinate({
      lat: coordinates[0]?.lng,
      lng: coordinates[0]?.lat,
    })
  }

  function resetModalStates() {
    setFormData({
      id: null,
      civility: null,
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      zip_code: "",
      state: "",
      state_code: "",
      street: "",
      street_number: "",
      city: "",
      zones: [],
      managers: [],
      categories: [],
      country: "Switzerland",
      country_code: "CH",
      role: "ROLE_FLYPRO",
      latitude: null,
      longitude: null,
      is_asca: false,
      is_rme: false,
      tags: {
        bio: "",
        experience: [],
        language: [],
      },
      agent_type: null,
    });
    setSelectedZones([]);
    setSelectedManagers([]);
    setSelectedCategories([]);
  }

  const fetchFilterCustomers = async () => {
    try {
      const response1 = await UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate);
      if (filters.firstname) {
        delete filters.firstname;
        filters['lastname'] = `${filterData.operator}:${filterData.search}`;
      } else if (filters.lastname) {
        delete filters.lastname;
        filters['firstname'] = `${filterData.operator}:${filterData.search}`;
      }
      const response2 = await UserService.indexByRole(filters, 'ROLE_FLYPRO', axiosPrivate);
      setCustomers([...response1, ...response2]);
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
    }
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    if (filterData.search != '') {
      filters[filterData.search_by] = `${filterData.operator}:${filterData.search}`;
    }

    if (filterData.status != '') {
      filters.status = filterData.status
    }
    await fetchCustomers();
  }

  const handleFilterChange = async (e) => {
    const target = e.target;

    if (target.name === 'search_by') {
      const search = document.querySelector('input[name="search"]');

      search.setAttribute('placeholder', `Search By ${target.options[target.selectedIndex].innerHTML}`);
      filterData.search = '';
      delete filters.name;
      delete filters.phone;
      delete filters.email;

      if (filterData.status != '') {
        filters.status = filterData.status
      }
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      await fetchCustomers();
    } else if (target.name === 'search' && target.value === '') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      delete filters.name;
      delete filters.phone;
      delete filters.email;
      if (filterData.status != '') {
        filters.status = filterData.status
      }
      await fetchCustomers();
    } else if (target.name === 'status') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      if (target.value != '') {
        filters.status = target.value
      } else {
        delete filters.status;
      }
      await fetchCustomers();
    }

    setFilterData({
      ...filterData,
      [target.name]: target.value
    });
  }

  const openManageModal = async (type, row) => {
    setModalType(type);
    setSelectedRow(row);

    if (type === "zones") {
      // Separate user zones and available zones
      const userZones = zones.filter((zone) =>
        row.zones.some((userZone) => userZone.id === zone.id)
      );
      const availableZones = zones.filter(
        (zone) => !userZones.some((userZone) => userZone.id === zone.id)
      );
      setSelectedZones(userZones);
      setAvailableZones(availableZones);
    }

    if (type === "managers") {
      const userManagers = managers.filter((manager) =>
        row.managers.some((userManager) => userManager.id === manager.id)
      );
      const availableManagers = managers.filter(
        (manager) => !userManagers.some((userManager) => userManager.id === manager.id)
      );
      setSelectedManagers(userManagers);
      setAvailableManagers(availableManagers);
    }

    if (type === "categories") {
      const userCategories = categories.filter((category) =>
        row.categories.some((userCategory) => userCategory.id === category.id)
      );
      const availableCategories = categories.filter(
        (category) => !userCategories.some((userCategory) => userCategory.id === category.id)
      );
      setSelectedCategories(userCategories);
      setAvailableCategories(availableCategories);
    }


    setIsOpenManage(true);
  };

  const handleAddZone = async (zoneToAdd) => {
    // Add the zone to the user's zones
    const updatedSelectedZones = [...selectedZones, zoneToAdd];
    const updatedAvailableZones = availableZones.filter(
      (zone) => zone.id !== zoneToAdd.id
    );
    setSelectedZones(updatedSelectedZones);
    setAvailableZones(updatedAvailableZones);
  };

  const handleRemoveZone = (zoneToRemove) => {
    // Remove the zone from selectedZones
    const updatedZones = selectedZones.filter(
      (zone) => zone.id !== zoneToRemove.id
    );

    // Add the removed zone to availableZones
    const updatedAvailableZones = [...availableZones, zoneToRemove];

    setSelectedZones(updatedZones);
    setAvailableZones(updatedAvailableZones);
  };

  const handleSaveZoneChanges = async () => {
    try {
      await UserService.update(selectedRow.id, {
        zones: selectedZones.map((z) => z.id),
      }, axiosPrivate);

      closeModalManage();
    } catch (error) {

    }
  };

  const handleAddManager = (managerToAdd) => {
    const updatedSelectedManagers = [...selectedManagers, managerToAdd];

    const updatedAvailableManagers = availableManagers.filter(
      (manager) => manager.id !== managerToAdd.id
    );

    setSelectedManagers(updatedSelectedManagers);
    setAvailableManagers(updatedAvailableManagers);
  };

  const handleRemoveManager = (managerToRemove) => {

    const updatedManagers = selectedManagers.filter(
      (manager) => manager.id !== managerToRemove.id
    );

    const updatedAvailableManagers = [...availableManagers, managerToRemove];

    setSelectedManagers(updatedManagers);
    setAvailableManagers(updatedAvailableManagers);
  };

  const handleSearch = () => {
    if (modalType === "managers") {
      // Filter available managers based on the search query
      const availableManagers = managers.filter(
        (manager) =>
          !selectedRow.managers.some((assignedManager) => assignedManager.id === manager.id)
      );

      const filteredManagers = availableManagers.filter((manager) =>
        manager.extra.full_name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setAvailableManagers(filteredManagers);
    }

    if (modalType === "zones") {
      const availableZonesFiltered = zones.filter(
        (zone) =>
          !selectedRow.zones.some((assignedZone) => assignedZone.id === zone.id)
      );

      const filteredZones = availableZonesFiltered.filter((zone) =>
        zone.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setAvailableZones(filteredZones);
    }

    if (modalType === "categories") {
      const availableCategoriesFiltered = categories.filter(
        (category) =>
          !selectedRow.categories.some((assignedCategory) => assignedCategory.id === category.id)
      );

      const filteredCategories = availableCategoriesFiltered.filter((category) =>
        category.title.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setAvailableCategories(filteredCategories);
    }
  };

  const handleSaveManagerChanges = async () => {
    try {
      await UserService.update(selectedRow.id, {
        managers: selectedManagers.map((m) => m.id),
      }, axiosPrivate);

      closeModalManage();
    } catch (error) {

    }
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  const handleEditMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id != event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }
    if (event.target.id != 'name') {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}${event.target.id}` })
      filters.sort = `${direction ? '' : '-'}${event.target.id}`;
    } else {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname` })
      filters.sort = `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname`;
    }
    await fetchCustomers();
  }

  const handleShowMap = async () => {
    await fetchCoordinates();
    setShowMap(true);
  }

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);

  }

  const blockUser = async (user) => {
    await UserService.update(user.id, { status: 'BLOCKED' }, axiosPrivate);
    fetchCustomers();
  }

  const unblockUser = async (user) => {
    await UserService.update(user.id, { status: 'ACTIVE' }, axiosPrivate);
    fetchCustomers();
  }

  const handleAddCategory = (categoryToAdd) => {
    const updatedSelectedCategories = [...selectedCategories, categoryToAdd];
    const updatedAvailableCategories = availableCategories.filter(
      (category) => category.id !== categoryToAdd.id
    );
    setSelectedCategories(updatedSelectedCategories);
    setAvailableCategories(updatedAvailableCategories);
  };

  const handleRemoveCategory = (categoryToRemove) => {
    const updatedSelectedCategories = selectedCategories.filter(
      (category) => category.id !== categoryToRemove.id
    );
    const updatedAvailableCategories = [...availableCategories, categoryToRemove];
    setSelectedCategories(updatedSelectedCategories);
    setAvailableCategories(updatedAvailableCategories);
  };

  const handleSaveCategoryChanges = async () => {
    try {
      await UserService.update(selectedRow.id, {
        categories: selectedCategories.map((p) => p.id),
      }, axiosPrivate);
      closeModalManage();
    } catch (error) {

    }
  };

  const languageOptions = [
    { value: 'english', label: 'English' },
    { value: 'french', label: 'French' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'portuguese', label: 'Portuguese' },
    { value: 'dutch', label: 'Dutch' },
  ];

  const agentTypeOptions = [
    { value: "EMPLOYEE", label: "Employee" },
    { value: "FREELANCER", label: "Freelancer" },
    { value: "EMPLOYEE_EXTRA", label: "Employee Extra" },
  ];

  return (
    <>
      <MenuHeader />

      <div className="bloc-search">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select
              name="search_by"
              value={filterData.search_by}
              onChange={handleFilterChange}
            >
              <option value="name">Name</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
            <select
              name="operator"
              value={filterData.operator}
              onChange={handleFilterChange}
            >
              <option value="contains">Contains</option>
              <option value="starts">Starts With</option>
              <option value="ends">Ends With</option>
              <option value="eq">Equal</option>
              <option value="!eq">Not Equal</option>
              <option value="in">In</option>
            </select>
            <select
              name="status"
              value={filterData.status}
              onChange={handleFilterChange}
            >
              <option value="">All Statuses</option>
              <option value="ACTIVE">Active</option>
              <option value="BLOCKED">Blocked</option>
              <option value="PENDING_EMAIL_VERIFICATION">Pending Email Verification	</option>
              <option value="PENDING_PHONE_VERIFICATION">Pending Phone Verification	</option>
            </select>
          </form>
          <button onClick={openModal} className="btn-add-client">
            Add
          </button>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th className="sort-header" id="id" onClick={handleSelectedSort}>ID</th>
                <th>Image</th>
                <th className="sort-header" id="firstname" onClick={handleSelectedSort}>Firstname</th>
                <th className="sort-header" id="lastname" onClick={handleSelectedSort}>Lastname</th>
                <th className="sort-header" id="phone" onClick={handleSelectedSort}>Phone</th>
                <th className="sort-header" id="email" onClick={handleSelectedSort}>Email</th>
                <th>Rating</th>
                <th>Team</th>
                <th className="sort-header" id="status" onClick={handleSelectedSort}>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customers?.length > 0 ? (
                customers.map((customer, index) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img
                        src={`${customer.media[0]?.original_url}`}
                        alt="Flypro image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{customer.firstname}</td>
                    <td>{customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>-</td>
                    <td>
                      {customer?.zones?.map(
                        (zone, i) => `${zone.name}${i === customer.zones.length - 1 ? '' : ', '}`
                      )}
                    </td>
                    <td className={customer?.status?.toLowerCase()}>
                      {asTitle(customer.status)}
                    </td>
                    <td>
                      <div
                        className="edit"
                        onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                        style={{ position: "relative", marginLeft: "25px" }}
                        onMouseLeave={handleEditMouseLeave}
                        onMouseEnter={handleEditMouseEnter}
                      >
                        &#8942;
                        {hoveredRow === index && (
                          <div className="dropdown-menu right">
                            <ul>
                              <li onClick={() => openModal(customer)}>Edit</li>
                              {customer.status !== 'BLOCKED' && (<li className="danger" onClick={() => blockUser(customer)}>Block Account</li>)}
                              {customer.status !== 'ACTIVE' && (<li className="success" onClick={() => unblockUser(customer)}>Activate Account</li>)}
                              <li onClick={() => openManageModal("zones", customer)}>Manage Zones</li>
                              <li onClick={() => openManageModal("managers", customer)}>Manage Managers</li>
                              <li onClick={() => openManageModal("categories", customer)}>Manage Categories</li>
                              <li className="danger"
                                onClick={() => handleDelete(customer.id)}
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No flypros found.</td>
                </tr>
              )}
            </tbody>
            <tfoot >
              <tr>
                <td colSpan={10}>
                  <div className="paginator">
                    <select value={perPage} onChange={handlePerPageChange} className="per-page">
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                      <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                      <span>{currentPage} / {lastPage}</span>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          {/* <div className="pagination">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className="pagination-button"
            >
              Précédent
            </button>
            <span>
              Page {currentPage + 1} sur {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage >= totalPages - 1}
              className="pagination-button"
            >
              Suivant
            </button>
          </div> */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >

        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>Ajouter un flypro</h2>
        <p>Si vous avez besoin de plus d'informations, veuillez consulter </p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input type="radio" name="civility" value="MS" id="MS" checked={formData.civility === "MS"} onChange={handleChange} />
                <label htmlFor="MS">Madame</label>
              </div>
              <div className="form-radio">
                <input type="radio" name="civility" value="MR" id="MR" checked={formData.civility === "MR"} onChange={handleChange} />
                <label htmlFor="MR">Monsieur</label>
              </div>
            </div>
            <div className="flex-row gap-10">
              <div className="form-text">
                <label>Prénom *</label>
                <input type="text" value={formData.firstname} name="firstname" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>Nom *</label>
                <input type="text" value={formData.lastname} name="lastname" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Numéro de téléphone portable *</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />
            </div>

            <div className="form-text">
              <label>Adresse email</label>
              <input type="email" value={formData.email} name="email" onChange={handleChange} />
            </div>

            <div className="flex-row gap-10">
              <div className="form-text">
                <label htmlFor="street">Select address</label>
                <Autocomplete
                  apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                  onPlaceSelected={handlePlaceSelect}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "CH" },
                  }}
                  style={{
                    height: "60px",
                    padding: "0 15px",
                    border: "0",
                    borderRadius: "20px",
                    boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                  }}
                  defaultValue={
                    formData.street
                      ? `${formData.street_number} ${formData.street}, ${formData.city}, ${formData.state} ${formData.zip_code}`
                      : ""
                  }
                  className={`address-input ${isZipCodeVisible ? "compact" : ""}`}
                />
              </div>

              {isZipCodeVisible && (
                <div className="form-group zip-code-input">
                  <label>ZIP Code</label>
                  <input
                    type="text"
                    value={formData.zip_code}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        zip_code: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
              )}

              <div className="form-group icon-top-margin">
                {formData.zip_code && (
                  <img
                    src="assets/images/map-icon.svg"
                    alt="Map Icon"
                    className="clickable"
                    onClick={handleShowMap}
                  />
                )}
              </div>
            </div>

            {formData.latitude && showMap && (<Map
              coordinates={[coordinates]}
              mode="edit"
              containerStyle={{ width: '100%', height: '400px' }}
              mapOptions={{
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeControl: false,
                fullscreenControl: true,
                streetViewControl: false,
              }}
              onCoordinateChange={handleCoordinateChange}
            />)}

            <div className="form-text">
              <label>Therapist zones</label>
              <Select
                value={selectedZones}
                onChange={handleZonesChange}
                name="state"
                options={zones.map((zone) => ({ value: zone.id, label: zone.name }))}
                styles={customSelectStyles}
                isClearable
                isMulti
                isSearchable
                placeholder="Select a zone"
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={() => "No Zone Available"} />
            </div>

            <div className="form-select">
              <label>Therapist Managers</label>
              <Select
                value={selectedManagers}
                onChange={handleManagersChange}
                name="state"
                options={managers.map((manager) => ({ value: manager.id, label: `${manager.extra.full_name} (${manager.email})` }))}
                styles={customSelectStyles}
                isClearable
                isMulti
                isSearchable
                placeholder="Select a Manager"
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={() => "No Manager Available"} />
            </div>

            <div className="form-text">
              <label>Categories</label>
              <Select
                value={selectedCategories}
                onChange={handleCategoriesChange}
                name="categories"
                options={categories?.map((category) => ({ value: category.id, label: category.title }))}
                styles={customSelectStyles}
                isClearable
                isMulti
                isSearchable
                placeholder="Select a category"
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={() => "No category Available"} />
            </div>

            {/* Bio */}
            <div className="form-text">
              <label>Bio</label>
              <textarea
                name="bio"
                value={formData?.tags?.bio}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    tags: { ...prev.tags, bio: e.target.value },
                  }))
                }
                placeholder="Enter bio here"
                rows="3"
                className="formTextArea"
              ></textarea>
            </div>

            {/* Experience */}
            <div className="form-text">
              <label>Experience</label>
              <div className="tags-management">
                <ul>
                  {formData.tags?.experience?.map((exp, index) => (
                    <li key={index}>
                      {exp}
                      <button
                        type="button"
                        onClick={() =>
                          setFormData((prev) => ({
                            ...prev,
                            tags: {
                              ...prev.tags,
                              experience: prev.tags.experience.filter((_, i) => i !== index),
                            },
                          }))
                        }
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <input
                  type="text"
                  value={formData?.newExperience || ""}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, newExperience: e.target.value }))
                  }
                  placeholder="Add new experience"
                />
                <button
                  type="button"
                  onClick={() => {
                    if (formData.newExperience) {
                      setFormData((prev) => ({
                        ...prev,
                        tags: {
                          ...prev.tags,
                          experience: [...(prev.tags?.experience || []), formData.newExperience],
                        },
                        newExperience: "",
                      }));
                    }
                  }}
                >
                  Add
                </button>
              </div>
            </div>

            {/* Language */}
            <div className="form-text">
              <label>Language</label>
              <div className="form-text">
                <Select
                  isMulti
                  value={formData.tags?.language?.map((lang) => ({
                    value: lang,
                    label: asTitle(lang),
                  }))}
                  onChange={(selectedOptions) =>
                    setFormData((prev) => ({
                      ...prev,
                      tags: {
                        ...prev.tags,
                        language: selectedOptions.map((option) => option.value),
                      },
                    }))
                  }
                  options={languageOptions}
                  styles={customSelectStyles}
                  placeholder="Select languages"
                  classNamePrefix="select"
                  noOptionsMessage={() => "No languages available"}
                />
              </div>
            </div>

            <div className="form-text">
              <label>Agent Type</label>
              <Select
                value={agentTypeOptions.find((option) => option.value === formData.agent_type)}
                onChange={(selectedOption) =>
                  setFormData((prev) => ({
                    ...prev,
                    agent_type: selectedOption ? selectedOption.value : null,
                  }))
                }
                options={agentTypeOptions}
                styles={customSelectStyles}
                isClearable
                placeholder="Select Agent Type"
                noOptionsMessage={() => "No agent type available"}
              />
            </div>

            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>ASCA</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.is_asca}
                    onChange={(e) => {
                      setFormData({ ...formData, is_asca: e.target.checked ? true : false })
                    }}
                    inputProps={{ 'aria-label': 'ASCA' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>

            <div className="capability-input topMargArea">
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography>RME</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>No</Typography>
                  <Switch
                    checked={formData.is_rme}
                    onChange={(e) => {
                      setFormData({ ...formData, is_rme: e.target.checked ? true : false })
                    }}
                    inputProps={{ 'aria-label': 'RME' }}
                  />
                  <Typography>Yes</Typography>
                </Stack>
              </Stack>
            </div>

            <button type="submit" className="btn-submit">
              {formData.id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenManage}
        onAfterOpen={afterOpenModalManage}
        onRequestClose={closeModalManage}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Manage Modal"
      >
        <div className="bloc-manage">
          {modalType === "zones" && (
            <div className="manage-zones-modal">
              <h2>Manage Zones for {selectedRow?.firstname} {selectedRow?.lastname}</h2>
              {/* Assigned Zones Section  */}
              <div className="currently-managed">
                <h3>Assigned Zones</h3>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {selectedZones.map((zone, index) => (
                        <thead key={index} className="no-background">
                          <tr>
                            <th>{zone.id}</th>
                            <th>{selectedRow?.firstname} {selectedRow?.lastname}</th>
                            <th>{zone.name}</th>
                            <th>
                              <button
                                className="form-submit"
                                onClick={() => handleRemoveZone(zone)}
                              >
                                Remove
                              </button>
                            </th>
                          </tr>
                        </thead>
                      ))}
                    </table>
                  </div>
                </div>
              </div>

              {/* Available Zones Section */}
              <div className="available-zones">
                <h3>Available Zones</h3>
                <div className="bloc-search no-background">
                  <div className="container search-center">
                    <div className="search">
                      <div className="text-search">
                        <input
                          type="text"
                          name="search"
                          placeholder="Search by Name"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-text"
                        />
                        <input type="submit" onClick={handleSearch} className="form-submit" value="Search" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {availableZones.map((zone, index) => (
                        <thead key={index} className="no-background">
                          <tr>
                            <th>{zone.id}</th>
                            <th>{zone.name}</th>
                            <th>
                              <button
                                className="form-submit"
                                onClick={() => handleAddZone(zone)}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                      ))}
                    </table>
                  </div>
                </div>
              </div>

              {/* Save Changes Button */}
              <button className="save-submit" onClick={handleSaveZoneChanges}>
                Save Changes
              </button>
            </div>
          )}

          {modalType === "managers" && (
            <div className="manage-managers-modal">
              <h2>Manage Managers for {selectedRow?.firstname} {selectedRow?.lastname}</h2>
              {/* Assigned Managers Section */}
              <div className="currently-managed">
                <h3>Assigned Managers</h3>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {
                        selectedManagers.map((manager, index) => (
                          <thead key={index} className="no-background">
                            <tr>
                              <th>{manager.id}</th>
                              <th>{manager?.extra?.full_name}</th>
                              <th>
                                <button
                                  className="form-submit"
                                  onClick={() => handleRemoveManager(manager)}
                                >
                                  Remove
                                </button>
                              </th>
                            </tr>
                          </thead>
                        ))
                      }
                    </table>
                  </div>
                </div>
              </div>

              {/* Available Managers Section */}
              <div className="available-managers">
                <h3>Available Managers</h3>
                <div className="bloc-search no-background">
                  <div className="container search-center">
                    <div className="search">
                      <div className="text-search">
                        <input
                          type="text"
                          name="search"
                          placeholder="Search by Name"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-text"
                        />
                        <input type="submit" onClick={handleSearch} className="form-submit" value="Search" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {availableManagers.map((manager, index) => (
                        <thead key={index} className="no-background">
                          <tr>
                            <th>{manager.id}</th>
                            <th colspan="3">{manager.extra.full_name}</th>
                            <th>{manager.email}</th>
                            <th></th>
                            <th>
                              <button
                                className="form-submit"
                                onClick={() => handleAddManager(manager)}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                      ))}
                    </table>
                  </div>
                </div>
              </div>

              {/* Save Changes Button */}
              <button className="save-submit" onClick={handleSaveManagerChanges}>
                Save Changes
              </button>
            </div>
          )}

          {modalType === "categories" && (
            <div className="manage-managers-modal">
              <h2>Manage Categories for {selectedRow?.firstname} {selectedRow?.lastname}</h2>
              {/* Assigned Categories Section */}
              <div className="currently-managed">
                <h3>Assigned Categories</h3>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {selectedCategories.map((category, index) => (
                        <thead key={index} className="no-background">
                          <tr>
                            <th>{category.id}</th>
                            <th>{category.title}</th>
                            <th>
                              <button
                                className="form-submit"
                                onClick={() => handleRemoveCategory(category)}
                              >
                                Remove
                              </button>
                            </th>
                          </tr>
                        </thead>
                      ))}
                    </table>
                  </div>
                </div>
              </div>

              {/* Available Categories Section */}
              <div className="available-managers">
                <h3>Available Categories</h3>
                <div className="bloc-search no-background">
                  <div className="container search-center">
                    <div className="search">
                      <div className="text-search">
                        <input
                          type="text"
                          name="search"
                          placeholder="Search by Name"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-text"
                        />
                        <input type="submit" onClick={handleSearch} className="form-submit" value="Search" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableau-list no-scroll">
                  <div className="container">
                    <table>
                      {availableCategories.map((category, index) => (
                        <thead key={index} className="no-background">
                          <tr>
                            <th>{category.id}</th>
                            <th>{category.title}</th>
                            <th>
                              <button
                                className="form-submit"
                                onClick={() => handleAddCategory(category)}
                              >
                                Add
                              </button>
                            </th>
                          </tr>
                        </thead>
                      ))}
                    </table>
                  </div>
                </div>
              </div>

              {/* Save Changes Button */}
              <button className="save-submit" onClick={handleSaveCategoryChanges}>
                Save Changes
              </button>
            </div>
          )}

        </div>
      </Modal>
    </>
  );
};

export default Flypro;

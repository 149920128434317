export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

export const exportClientBookingsUrl = (hotelId) => 
  `${API_BASE_URL}/admin/hotel-bookings/export/client-bookings?hotel_id=${hotelId}`;

export const exportDirectHotelBookingsUrl = (hotelId) => 
  `${API_BASE_URL}/admin/hotel-bookings/export/direct-hotel-bookings?hotel_id=${hotelId}`;

export const getHotelBookingsUrl = (hotelId) => 
  `${API_BASE_URL}/admin/hotel-bookings/hotel/${hotelId}/bookings`;

export const createHotelAccount = (hotelId) => 
    `${API_BASE_URL}/admin/hotels/${hotelId}/create-account`;

export const endpoints = {
  csrf:'/sanctum/csrf-cookie',
  loginUrl: '/auth/login',
  forgetUrl: '/auth/password/reset',
  importCalender: '/calendars_managements/import_schedule',
  getCalendarByDate: '/admin/calendar/by-date',
  getCalendarFile: 'calendars_managements/download_schedule_template',
  getAvailibility: '/admin/calendar/availibility',
  refreshToken: '/auth/me?with=roles,primary_address,hotel.address,notifications',
  getProfil: '/client/profil',
  invite: '/admin/invite',
  update: '/admin/user/update',
  register: '/client/register',
  verify: '/client/verify/otp',
  getAdminUser: '/admin/users',
  getProduct: '/admin/product',
  getPromotions: '/admin/promotions',
  newPromotions: '/admin/promotions',
  generateCodePromotions: '/admin/promotion/generate-code',
  newProduct: '/admin/product/new',
  ActiveProduct: '/admin/product/active',
  newDomaine: '/admin/domains/new',
  newCategory: '/admin/category/new',
  newSubCategory: '/admin/subcategory/new',
  newSubProduct: '/admin/subproduct/new',
  editDomaine: '/admin/domains/update',
  editCategory: '/admin/category/update',
  editSubCategory: '/admin/subcategory/update',
  editSubProduct: '/admin/subproduct/update',
  editProduct: '/admin/product/update',
  getDomain: '/admin/domains',
  getCategory: '/admin/category',
  getCategoryByDomaine: '/admin/category/domain',
  getSubCategoryByCategory: '/admin/subcategory/category',
  getSubCategory: '/admin/subcategory',
  getProductByCategory: '/admin/product/category',
  getProductBySubCategory: '/admin/product/subcategory',
  getZone: '/admin/zones',
  createZone: '/admin/zones/create',
  updateZone: '/admin/zones/update',
  getNotification: '/admin/notifications',
  newNotification: '/admin/notifications/new',
  deleteNotification: '/notifications/delete',
  getHotels: '/admin/hotels',
  createHotel: '/admin/hotels/create',
  getHotelBookings: '/admin/hotel/bookings',
  getAdminProfil: '/admin/profil',
  UpdateAdminProfil: '/admin/user/update',
  UpdateUserPassword: '/admin/update/password',
};
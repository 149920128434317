import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CiClock2, CiCalendar } from "react-icons/ci";
import BookingService from "../services/booking.service";
import ProductService from "../services/product.service";
import CategoryService from "../services/category.service";
import UserService from "../services/user.service";
import Select from 'react-select';
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import { formatTimeTo24Hour } from "../shared/utils/string";
import { Map } from "../components/google_map";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
        height: '680px',
    },
};


const customSelectStyles = {
    control: (styles) => ({
        ...styles,
        width: '100%',
        height: '60px',
        padding: '0 15px',
        fontSize: '14px',
        lineHeight: '60px',
        color: '#393D44',
        border: '0',
        borderRadius: '20px',
        background: '#ffffff',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
};

const CreateTaskModal = ({ axiosPrivate }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [value, setValue] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const [flypros, setFlypros] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [isAddressUpdate, setIsAddressUpdate] = useState(false);
    const [coordinates, setCoordinate] = useState({ lat: 46.356, lng: 6.5641 });
    const [selectedState, setSelectedState] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
    const notify = useNotifications();
    const { auth } = useAuth();
    const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);

    const [formData, setFormData] = useState({
        category_id: "",
        subcategory_id: "",
        product_id: "",
        duration: "",
        date: "",
        date_only: "",
        timeBooking: "",
        description: "",
        message: "",
        postcode: "",
        therapist_id: [],
        client_id: "",
        address_id: "",
        status: "CREATED",
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        country: "Switzerland",
        country_code: "CH",
        latitude: null,
        longitude: null,
        hotel_id: auth.user.hotel[0]?.id,
    });

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk",
        libraries: ['places'],
    });

    const resetForm = () => {
        setFormData({
            category_id: "",
            subcategory_id: "",
            product_id: "",
            duration: "",
            date: "",
            date_only: "",
            timeBooking: "",
            description: "",
            message: "",
            postcode: "",
            therapist_id: [],
            client_id: "",
            address_id: "",
            status: "CREATED",
            zip_code: "",
            state: "",
            state_code: "",
            street: "",
            street_number: "",
            city: "",
            country: "Switzerland",
            country_code: "CH",
            latitude: null,
            longitude: null,
            hotel_id: auth.user.hotel[0]?.id,
        });
        setSelectedFlyproOption([]);
        setSubCategories([]);
        setProductList([]);
        setCoordinate({ lat: 46.356, lng: 6.5641 });
        setIsAddressUpdate(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (isAddressUpdate || (formData.zip_code && !formData.latitude)) {
            try {
                await fetchCoordinates();
                setIsAddressUpdate(false);
            } catch (error) {
                notify.error('Error validating address');
                return;
            }
        }

        try {
            //const state = cantons.cantons.find(c => c.name === formData.state);
            const time = formatTimeTo24Hour(formData.timeBooking);
            const combinedDateTime = `${formData.date_only} ${time}`;

            const payload = {
                ...formData,
                date: combinedDateTime,
                start_before: combinedDateTime,
                latitude: coordinates.lat,
                longitude: coordinates.lng,
            };

            const response = await BookingService.store(payload, axiosPrivate);
            if (response.data) {
                closeModal();
            }
        } catch (error) {

        }
    };

    const openModal = async () => {
        resetForm();
        try {
            const [categoriesRes, customersRes] = await Promise.all([
                CategoryService.index({ parent_id: null, status: 'ACTIVE' }, axiosPrivate),
                UserService.indexByRole({ with: 'primary_address' }, 'ROLE_CLIENT', axiosPrivate)
            ]);
            setCategories(categoriesRes);
            setCustomers(customersRes);
            setIsOpen(true);
        } catch (error) {
            notify.error('Error initializing form');
        }
    };

    const closeModal = () => {
        resetForm();
        setIsOpen(false);
    };

    // Handle category change
    const handleCategorySelectChange = async (selected) => {
        const categoryId = selected ? selected.value : '';
        setFormData(prev => ({ ...prev, category_id: categoryId, subcategory_id: '', product_id: '' }));

        if (categoryId !== '') {
            try {
                const response = await CategoryService.index({ parent_id: categoryId, status: 'ACTIVE' }, axiosPrivate);
                setSubCategories(response);
            } catch (error) {

            }
        } else {
            setSubCategories([]);
        }
    };

    // Handle subcategory change
    const handleSubcategorySelectChange = async (selected) => {
        const subcatId = selected ? selected.value : '';
        setFormData(prev => ({ ...prev, subcategory_id: subcatId, product_id: '' }));

        if (subcatId !== '') {
            try {
                const response = await ProductService.index({ category_id: subcatId }, axiosPrivate);
                setProductList(response);
            } catch (error) {

            }
        } else {
            setProductList([]);
        }
    };

    // Handle product change
    const handleProductSelectChange = (selected) => {
        setFormData(prev => ({
            ...prev,
            product_id: selected ? selected.value : null,
            duration: selected ? selected.duration : ''

        }));
    };

    // Handle flypro change
    const handleFlyproChange = (selected) => {
        console.log({ selected })
        if (selected.length > 2) {
            return;
        }
        if (selected?.length) {
            setSelectedFlyproOption(selected);
            setFormData((prev) => ({
                ...prev,
                therapist_id: selected.map((therapist) => therapist.value)
            }));
        } else {
            setSelectedFlyproOption([]);

            setFormData((prev) => ({
                ...prev,
                therapist_id: []
            }));
        }
    };

    // Handle Customer change
    const handleCustomerChange = (selected) => {
        let client = customers.find((customer) => customer.id === selected?.value);
        if (client && client.primary_address) {
            setFormData((prev) => ({
                ...prev,
                zip_code: client.primary_address?.zip_code,
                state: client.primary_address?.state,
                state_code: client.primary_address?.state_code,
                street: client.primary_address?.street,
                street_number: client.primary_address?.street_number,
                city: client.primary_address?.city,
                latitude: client.primary_address?.latitude,
                longitude: client.primary_address?.longitude,
                address_id: client.primary_address?.id,
                client_id: selected ? selected.value : ''
            }));
            setSelectedState({ value: client.primary_address.state, label: client.primary_address.state })
            setCoordinate({ lat: client.primary_address?.latitude, lng: client.primary_address?.longitude })
        } else {
            setFormData((prev) => ({
                ...prev,
                zip_code: "",
                state: "",
                state_code: "",
                street: "",
                street_number: "",
                city: "",
                latitude: "",
                address_id: null,
                longitude: "",
                client_id: selected ? selected.value : ''
            }));
        }
    };

    // Create options for Category, Subcategory, Product, Flypro
    const categoryOptions = categories?.length > 0 ? categories.map(row => ({ value: row.id, label: row.title })) : [];
    const subcategoryOptions = subCategories?.length > 0 ? subCategories.map(row => ({ value: row.id, label: row.title })) : [];
    const productOptions = productList?.length > 0 ? productList.map(row => ({ value: row.id, label: row.name, duration: row.duration })) : [];
    const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];
    const customerOptions = customers?.length > 0 ? customers.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

    // Determine currently selected options
    const selectedCategoryOption = categoryOptions.find(o => o.value === formData.category_id) || null;
    const selectedSubcategoryOption = subcategoryOptions.find(o => o.value === formData.subcategory_id) || null;
    const selectedProductOption = productOptions.find(o => o.value === formData.product_id) || null;
    // const selectedFlyproOption = flyproOptions.find(option => formData.therapist_id.includes(option.value)) || null;
    const selectedCustomerOption = customerOptions.find(option => option.value === formData.client_id) || null;
    // Fonction pour mettre à jour les valeurs du formulaire

    const handleChange = async (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

    };

    const fetchCoordinates = async () => {
        const { street_number, street, city, state, zip_code } = formData;
        const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json`,
                {
                    params: {
                        address,
                        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    },
                }
            );

            if (response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                setFormData((prev) => ({
                    ...prev,
                    latitude: location.lat,
                    longitude: location.lng,
                }));
                setCoordinate({
                    lat: location.lat,
                    lng: location.lng,
                });
            } else {
                console.error('No results found for the given address.');
            }
        } catch (error) {
            console.error('Error fetching coordinates:', error);
        }
    };

    const handleShowMap = async () => {
        await fetchCoordinates();
        setShowMap(true);
    };

    const fetchAvailableTherapists = async () => {
        try {
            console.log({ date: formData.date, time: formData.timeBooking, latitude: formData.latitude, longitude: formData.longitude })
            const flypro = await UserService.fetchAvailableTherapists({ is_hotel: true, date: formData.date_only, time: formatTimeTo24Hour(formData.timeBooking), duration: Number(formData.duration), lat: formData.latitude, lng: formData.longitude, zip_code: formData.zip_code }, null, axiosPrivate);
            setFlypros(flypro);
        } catch (error) {

        }
    }

    const loadTherapist = async () => {
        if (formData.longitude && formData.latitude && formData.duration && formData.date_only && formData.timeBooking && formData.state) {
            await fetchAvailableTherapists();
        } else {
            notify.warn("Missing Data to get available therapists")
        }
    }

    const handleCoordinateChange = (coordinates) => {
        setFormData({
            ...formData,
            longitude: coordinates[0]?.lng,
            latitude: coordinates[0]?.lat,
        })
        setCoordinate({
            lat: coordinates[0]?.lng,
            lng: coordinates[0]?.lat,
        })
    }

    const handleStateChange = (data) => {
        let selected = cantons.cantons.find(
            (canton) => canton.name === data?.value
        );
        document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');

        setFormData((prev) => ({
            ...prev,
            zip_code: '',
            state: data?.value,
        }));

        setSelectedState(data);
    };

    const handlePlaceSelect = (place) => {
        if (!place || !place.address_components) {
            console.error("Invalid place selected");
            return;
        }

        let updatedAddress = {
            street: '',
            street_number: '',
            city: '',
            state: '',
            state_code: '',
            zip_code: '',
            country: 'Switzerland',
            country_code: 'CH',
            latitude: place.geometry?.location?.lat() || null,
            longitude: place.geometry?.location?.lng() || null,
        };

        place.address_components.forEach((component) => {
            const type = component.types[0];
            switch (type) {
                case "street_number":
                    updatedAddress.street_number = component.long_name;
                    break;
                case "route":
                    updatedAddress.street = component.long_name;
                    break;
                case "locality":
                    updatedAddress.city = component.long_name;
                    break;
                case "postal_code":
                    updatedAddress.zip_code = component.long_name;
                    break;
                case "administrative_area_level_1":
                    updatedAddress.state = component.long_name;
                    updatedAddress.state_code = component.short_name;
                    break;
            }
        });

        setFormData((prev) => ({
            ...prev,
            ...updatedAddress,
        }));

        // 🔹 Force update coordinates
        setCoordinate({
            lat: updatedAddress.latitude,
            lng: updatedAddress.longitude,
        });

        console.log("Updated Coordinates:", updatedAddress.latitude, updatedAddress.longitude);
        setShowMap(true); // 🔹 Ensure the map shows up
    };

    return (
        <>
            <button onClick={openModal} className="btn-add-client">
                Create a task
            </button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add a Booking"
            >
                <button className="close-button" onClick={closeModal}>
                    <span className="close-icon">✖</span>
                </button>

                <h2>Create a task</h2>
                <p>If you need more info, please check out</p>
                <div className="form-inscription">
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-text">
                            <label>Category*</label>
                            <Select
                                value={selectedCategoryOption}
                                onChange={handleCategorySelectChange}
                                options={categoryOptions}
                                styles={customSelectStyles}
                                isClearable
                                required
                                isSearchable
                                placeholder="Select a category"
                                noOptionsMessage={() => "No categories available"}
                            />
                        </div>

                        <div className="form-text">
                            <label>Subcategory*</label>
                            <Select
                                value={selectedSubcategoryOption}
                                onChange={handleSubcategorySelectChange}
                                options={subcategoryOptions}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a Subcategory"
                                noOptionsMessage={() => "No Subcategories available"}
                                isDisabled={!formData.category_id} // disable if no category selected
                            />
                        </div>

                        <div className="form-text">
                            <label>Product*</label>
                            <Select
                                value={selectedProductOption}
                                onChange={handleProductSelectChange}
                                options={productOptions}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a product"
                                noOptionsMessage={() => "No products available"}
                                isDisabled={!formData.subcategory_id} // disable if no subcategory selected
                            />
                        </div>

                        <div className="form-text">
                            <label>Duration</label>
                            <input type="number" readOnly name="duration" value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} />
                        </div>

                        <div className="dates">
                            <div className="form-text dateOfTheBookingParent topMarg">
                                <label>Date of the booking</label>

                                <input
                                    type="date"
                                    name="date_only"
                                    value={formData.date_only}
                                    onChange={(e) => setFormData({ ...formData, date_only: e.target.value })}
                                />
                            </div>

                            <div className="form-text timeOfTheBookingParent topMarg">
                                <label>Time of the booking</label>
                                <input
                                    type="time"
                                    name="timeBooking"
                                    value={formData.timeBooking}
                                    onChange={(e) => setFormData({ ...formData, timeBooking: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="form-text topMargArea">
                            <label>Description</label>
                            <textarea className="formTextArea" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                        </div>

                        <div className="form-text topMargArea">
                            <label>Comment</label>
                            <textarea className="formTextArea" name="message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                        </div>

                        <div className="form-text">
                            <label>Customer</label>
                            <Select
                                value={selectedCustomerOption}
                                onChange={handleCustomerChange}
                                options={customerOptions}
                                styles={customSelectStyles}
                                required
                                isClearable
                                isSearchable
                                placeholder="Select a Customer"
                                noOptionsMessage={() => "No Customers available"}
                            />
                        </div>
                        <div className="flex-row gap-10">
                            <div className="form-text">
                                <label htmlFor="street">Saisissez une adresse</label>
                                <Autocomplete
                                    apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                                    onPlaceSelected={handlePlaceSelect}
                                    options={{
                                        types: ["address"],
                                        componentRestrictions: { country: "CH" },
                                    }}
                                    style={{
                                        height: "60px",
                                        padding: "0 15px",
                                        border: "0",
                                        borderRadius: "20px",
                                        boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                                    }}
                                    defaultValue={
                                        formData.street
                                            ? `${formData.street_number} ${formData.street}, ${formData.city}, ${formData.state} ${formData.zip_code}`
                                            : ""
                                    }
                                    className={`address-input ${isZipCodeVisible ? "compact" : ""}`}
                                />
                            </div>

                            {isZipCodeVisible && (
                                <div className="form-group zip-code-input">
                                    <label>ZIP Code</label>
                                    <input
                                        type="text"
                                        value={formData.zip_code}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                zip_code: e.target.value,
                                            }))
                                        }
                                        required
                                    />
                                </div>
                            )}

                            <div className="form-group icon-top-margin">
                                {formData.zip_code && (
                                    <img
                                        src="assets/images/map-icon.svg"
                                        alt="Map Icon"
                                        className="clickable"
                                        onClick={handleShowMap}
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            {formData.latitude && showMap && (<Map
                                coordinates={[coordinates]}
                                mode="edit"
                                containerStyle={{ width: '100%', height: '400px' }}
                                mapOptions={{
                                    disableDefaultUI: true,
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    fullscreenControl: true,
                                    streetViewControl: false,
                                }}
                                onCoordinateChange={handleCoordinateChange}
                            />)}
                        </div>
                        <div className="form-text" onClick={loadTherapist}>
                            <label>Flypro</label>
                            <Select
                                value={selectedFlyproOption}
                                onChange={handleFlyproChange}
                                options={flyproOptions}
                                styles={customSelectStyles}
                                isClearable
                                required
                                isSearchable
                                isMulti
                                placeholder="Select a Flypro"
                                noOptionsMessage={() => "No Flypros available"}
                            />
                        </div>

                        {/* <div className="form-text">
              <label>Promo CODE</label>
              <input type="text" name="promoCode" value={formData.promoCode} onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })} />
            </div> */}
                        <button type="submit" className="btn-submit">
                            Create Task
                        </button>
                    </form>
                </div>
            </Modal>

        </>
    );
};

export default CreateTaskModal;
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './components/Dashboard';
import ListTasks from './components/TaskList/ListTable';
import Product from './components/Product';
import "./App.css";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import RequireGuest from "./components/RequireGuest";
import PersistLogin from "./components/PersistLogin";
import Customer from "./components/Customer";
import Flypro from "./components/Flypro";
import Managers from "./components/User";
import Promotion from "./components/Promotion";
import Management from "./components/Management";
import Hotels from "./components/Hotels";
import Notification from "./components/Notification";
import Calendar from "./components/Calendar";
import ManagerProduct from "./components/HotelManager/Products";
import { useEffect } from "react";
import { generateToken, messaging } from "./utils/firebase";
import toast, { Toaster } from 'react-hot-toast';
import { onMessage } from "@firebase/messaging";
import NotificationAdmin from "./components/NotificationAdmin";

const ROLES = {
  Client: "ROLE_CLIENT",
  HotelManager: "ROLE_HOTEL_MANAGER",
  Admin: "ROLE_ADMIN",
  Manager: "ROLE_MANAGER",
};
function App() {

  useEffect(() => {
    generateToken();
    console.log(generateToken())
    onMessage(messaging, (payload) => {
      console.log(payload);
      toast(<a
        href={payload.data.url}
        rel="noopener noreferrer"
        style={{
          color: "#000000",
          textDecoration: "underline",
        }}
      >
        {payload.notification.body}
      </a>);
    });
  }, []);
  return (
    <>
      <Toaster position="top-right" />
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route element={<PersistLogin />}>
            <Route element={<RequireGuest />}>
              <Route path="login" element={<Login />} />
              <Route path="forget_password" element={<ForgetPassword />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Manager, ROLES.HotelManager]} />}>
              <Route path="" element={<Dashboard />} />
              <Route path="Customer" element={<Customer />} />
              <Route path="Flypro" element={<Flypro />} />
              <Route path="products" element={<Product />} />
              <Route path="Hotels" element={<Hotels />} />
              <Route path="tasks" element={<ListTasks />} />
              <Route path="calendar" element={<Calendar />} />

            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.HotelManager]} />}>
              <Route path="Managers" element={<Managers />} />
              <Route path="notification" element={<Notification />} />
              <Route path="notification-list" element={<NotificationAdmin />} />
              <Route path="promotion" element={<Promotion />} />
              <Route path="Management" element={<Management />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.HotelManager]} />}>
              <Route path="manager-products" element={<ManagerProduct />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;

// ChooseLocation.js
import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Modal from 'react-modal';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import UserService from "../services/user.service";
import cantons from "../shared/data/cantons.json";
import { useNotifications } from '../context/NotificationProvider';
import { asTitle, castDate } from '../shared/utils/string';
import { Map } from "./google_map";
import axios from "../api/axios";
import Select from 'react-select';
import BookingService from "../services/booking.service";
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import imageFile from "../assets/images/upload-file.svg";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Autocomplete from 'react-google-autocomplete';
import { useLoadScript } from '@react-google-maps/api';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "95vh",
    scrollbarWidth: "none",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Customer = () => {

  const axiosMultiPart = useAxiosMultiPart();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const notify = useNotifications();
  const [customers, setCustomers] = useState([]); // État pour stocker les données des clients
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [formData, setFormData] = useState({
    id: null,
    civility: null,
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    birthdate: "",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    country: "Switzerland",
    country_code: "CH",
    role: "ROLE_CLIENT",
    latitude: null,
    longitude: null
  });
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);
  const [filterData, setFilterData] = useState({
    search: "",
    search_by: "name",
    operator: "contains",
    status: "",
  });
  const [filters, setFilters] = useState({
    with: 'primary_address,media,id',
    page: currentPage,
    per_page: perPage,
    id: id ? `in:${id}` : "",
  });
  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [coordinates, setCoordinate] = useState({
    lat: 46.356,
    lng: 6.5641
  });

  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '60px',
      padding: '0 15px',
      fontSize: '14px',
      lineHeight: '60px',
      color: '#393D44',
      border: '0',
      borderRadius: '20px',
      background: '#ffffff',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    placeholder: (base) => ({
      ...base,
      marginTop: '-10px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      marginTop: '-10px',
    }),
  };
  const [selectedState, setSelectedState] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const timeoutRef = useRef(null);

  const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
  const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerBookings, setCustomerBookings] = useState([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk", // Ensure you use your actual API Key
    libraries: ['places'],
  });



  /* ========================================== */

  const handleNextPage = () => {
    setFilters({ ...filters, page: currentPage + 1 });
    setCurrentPage(currentPage + 1);
  }

  const handlePrevPage = () => {
    setFilters({ ...filters, page: currentPage - 1 });
    setCurrentPage(currentPage - 1);
  }

  const handleLastPage = () => {
    setFilters({ ...filters, page: lastPage });
    setCurrentPage(lastPage);
  }

  const handleFirstPage = () => {
    setFilters({ ...filters, page: 1 });
    setCurrentPage(1);
  }

  const handlePerPageChange = (e) => {
    setFilters({ ...filters, per_page: e.target.value, page: 1 });
    setPerPage(e.target.value);
    setCurrentPage(1);

  }



  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value, // Update the phone field
    }));
  };
  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected.id != event.target.id) {
      selected.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }
    if (event.target.id != 'name') {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}${event.target.id}` })
      filters.sort = `${direction ? '' : '-'}${event.target.id}`;
    } else {
      setFilters({ ...filters, sort: `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname` })
      filters.sort = `${direction ? '' : '-'}firstname,${direction ? '' : '-'}lastname`;
    }
    await fetchCustomers();
  }
  const handleStateChange = (data) => {
    setIsAddressUpdate(true);
    let selected = cantons.cantons.find(
      (canton) => canton.name === data?.value
    );
    document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');
    setFormData({
      ...formData, zip_code: ''
    });
    setFormData({
      ...formData,
      state: data?.value,
    });
    setSelectedState(data);
  };
  // Fonction pour mettre à jour les valeurs du formulaire
  const handleChange = async (e) => {

    if (formData.id && e.target.name === 'zip_code' || e.target.name === 'street' || e.target.name === 'street_number' || e.target.name === 'city') {
      setIsAddressUpdate(true);
    }

    // Handle file input
    if (e.target.name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    formData.birthdate = formData.birthdate === '' ? null : formData.birthdate;
    formData.role = 'ROLE_CLIENT';
    formData.country = "Switzerland";
    formData.country_code = "CH";

    const formDataToSend = new FormData();

    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }

    if (file) {
      formDataToSend.append("imageFile", file);
      formData.file = file;
    }

    try {
      const response = await (formData.id ? UserService.update(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate) : UserService.store(formDataToSend, file ? axiosMultiPart : axiosPrivate));
      if (response.data) {
        closeModal();
        fetchCustomers();
      }
    } catch (e) {
      // errors handled in interceptor
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await UserService.indexByRole(filters, 'ROLE_CLIENT', axiosPrivate);
      setCustomers(response.data);
      console.log(response)
      setTotal(response.total);
      setLastPage(response.last_page);
      if (response.last_page < currentPage) {
        setFilters({ ...filters, page: response.last_page })
        setCurrentPage(response.last_page);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
    }
  };



  // Fonction pour calculer la date minimale (18 ans avant aujourd'hui)
  const calculateMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 18, // Soustraire 18 ans à l'année actuelle
      today.getMonth(),
      today.getDate()
    );
    return minDate.toLocaleDateString('en-CA').split("T")[0]; // Formater la date en YYYY-MM-DD
  };
  // Requête API pour obtenir les clients avec pagination
  useEffect(() => {
    fetchCustomers();
  }, [currentPage, perPage]); // Recharger les données à chaque changement de page

  function openModal(customer) {
    if (customer && customer?.id) {
      let phone = customer.phone;
      if (phone && phone.startsWith("0")) {
        phone = "+41" + phone.substring(1);
      }

      setFormData({
        ...formData,
        ...customer,
        ...{
          primary_address_id: customer.primary_address?.id,
          zip_code: customer.primary_address?.zip_code,
          state: customer.primary_address?.state,
          state_code: customer.primary_address?.state_code,
          street: customer.primary_address?.street,
          street_number: customer.primary_address?.street_number,
          city: customer.primary_address?.city,
          country: customer.primary_address?.country,
          country_code: customer.primary_address?.country_code,
          longitude: customer.primary_address?.longitude,
          latitude: customer.primary_address?.latitude,
          phone
        },
      });
      if (customer.primary_address?.state) {
        setSelectedState({
          value: customer.primary_address?.state,
          label: customer.primary_address?.state
        })
      }
      if (customer.primary_address?.longitude) {
        setCoordinate({
          lat: customer.primary_address?.latitude,
          lng: customer.primary_address?.longitude,
        })
      }

    }
    setShowMap(false);
    setIsOpen(true);
  }



  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function closeModal() {
    setFormData({
      id: null, //
      civility: null,
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      birthdate: "",
      zip_code: "",
      state: "",
      state_code: "",
      street: "",
      street_number: "",
      city: "",
      country: "Switzerland",
      country_code: "CH",
      role: "ROLE_CLIENT",
      latitude: null,
      longitude: null
    });
    setCoordinate({
      lat: 46.356,
      lng: 6.5641
    })
    setIsAddressUpdate(false);
    setIsOpen(false);
    setSelectedState(null);
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    if (filterData.search != '') {
      filters[filterData.search_by] = `${filterData.operator}:${filterData.search}`;
    }

    if (filterData.status != '') {
      filters.status = filterData.status
    }
    await fetchCustomers();
  }

  const handleFilterChange = async (e) => {
    const target = e.target;

    if (target.name === 'search_by') {
      const search = document.querySelector('input[name="search"]');

      search.setAttribute('placeholder', `Search By ${target.options[target.selectedIndex].innerHTML}`);
      filterData.search = '';
      delete filters.name;
      delete filters.phone;
      delete filters.email;

      if (filterData.status != '') {
        filters.status = filterData.status
      }
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      await fetchCustomers();
    } else if (target.name === 'search' && target.value === '') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      delete filters.name;
      delete filters.phone;
      delete filters.email;
      if (filterData.status != '') {
        filters.status = filterData.status
      }
      await fetchCustomers();
    } else if (target.name === 'status') {
      setFilterData({
        ...filterData,
        [target.name]: target.value
      });
      if (target.value != '') {
        filters.status = target.value
      } else {
        delete filters.status;
      }
      await fetchCustomers();
    }

    setFilterData({
      ...filterData,
      [target.name]: target.value
    });
  }

  const blockUser = async (user) => {
    await UserService.update(user.id, { status: 'BLOCKED' }, axiosPrivate);
    fetchCustomers();
  }

  const unblockUser = async (user) => {
    await UserService.update(user.id, { status: 'ACTIVE' }, axiosPrivate);
    fetchCustomers();
  }

  const fetchCoordinates = async () => {
    const { street_number, street, city, state, zip_code } = formData;
    const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          },
        }
      );

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setFormData({
          ...formData,
          latitude: location.lat,
          longitude: location.lng,
        });
        setCoordinate({
          lat: location.lat,
          lng: location.lng
        });
      } else {
        console.error('No results found for the given address.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const handleCoordinateChange = (coordinates) => {
    setFormData({
      ...formData,
      longitude: coordinates[0]?.lng,
      latitude: coordinates[0]?.lat,
    })
    setCoordinate({
      lat: coordinates[0]?.lng,
      lng: coordinates[0]?.lat,
    })
  }

  const handleShowMap = async () => {
    await fetchCoordinates();
    setShowMap(true);
  }

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  // Open Details Modal
  const openDetailsModal = (customer) => {
    setSelectedCustomer(customer);
    setDetailsModalIsOpen(true);
  };

  // Close Details Modal
  const closeDetailsModal = () => {
    setSelectedCustomer(null);
    setDetailsModalIsOpen(false);
  };

  // Open History Modal
  const openHistoryModal = async (customer) => {
    setSelectedCustomer(customer);
    try {
      const response = await BookingService.index({ with: 'product', client_id: customer.id },
        axiosPrivate
      );
      console.log({ response })
      setCustomerBookings(response);
    } catch (error) {

    }
    setHistoryModalIsOpen(true);
  };

  // Close History Modal
  const closeHistoryModal = () => {
    setSelectedCustomer(null);
    setCustomerBookings([]);
    setHistoryModalIsOpen(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await UserService.delete(id, axiosPrivate);
      fetchCustomers();
    } catch (error) {

    }
  };

  const handlePlaceSelect = (place) => {
    const zipCode = place.address_components.find((component) =>
      component.types.includes("postal_code")
    )?.long_name;

    const addressComponents = place.address_components;
    const updatedAddress = {
      street: '',
      city: '',
      zip_code: '',
      street_number: '',
      state: '',
      state_code: '',
      country: 'Switzerland',
      country_code: 'CH',
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    if (zipCode) {
      setFormData((prev) => ({ ...prev, zip_code: zipCode }));
      setIsZipCodeVisible(false);
    } else {
      setIsZipCodeVisible(true);
    }

    addressComponents.forEach(component => {
      const type = component.types[0];
      switch (type) {
        case 'street_number':
          updatedAddress.street_number = component.long_name;
          break;
        case 'route':
          updatedAddress.street = component.long_name;
          break;
        case 'locality':
          updatedAddress.city = component.long_name;
          break;
        case 'postal_code':
          updatedAddress.zip_code = component.long_name;
          break;
        case 'administrative_area_level_1':
          updatedAddress.state = component.long_name;
          updatedAddress.state_code = component.short_name;
          break;
      }
    });

    setFormData((prev) => ({
      ...prev,
      street: updatedAddress.street,
      street_number: updatedAddress.street_number,
      city: updatedAddress.city,
      state: updatedAddress.state,
      zip_code: updatedAddress.zip_code,
      state_code: updatedAddress.state_code,
      latitude: updatedAddress.lat,
      longitude: updatedAddress.lng,
    }));
    setShowMap(false);
  };



  return (
    <>
      <MenuHeader />
      <div className="bloc-search">
        <div className="container">
          <form onSubmit={handleFilterSubmit} className="search">
            <div className="text-search">
              <input
                type="text"
                name="search"
                value={filterData.search}
                onChange={handleFilterChange}
                placeholder="Search by Name"
                className="form-text"
              />
              <input type="submit" className="form-submit" value="Search" />
            </div>
            <select
              name="search_by"
              value={filterData.search_by}
              onChange={handleFilterChange}
            >
              <option value="name">Name</option>
              <option value="email">Email</option>
              <option value="phone">Phone</option>
            </select>
            <select
              name="operator"
              value={filterData.operator}
              onChange={handleFilterChange}
            >
              <option value="contains">Contains</option>
              <option value="starts">Starts With</option>
              <option value="ends">Ends With</option>
              <option value="eq">Equal</option>
              <option value="!eq">Not Equal</option>
              <option value="in">In</option>
            </select>
            <select
              name="status"
              value={filterData.status}
              onChange={handleFilterChange}
            >
              <option value="">All Statuses</option>
              <option value="ACTIVE">Active</option>
              <option value="BLOCKED">Blocked</option>
              <option value="PENDING_EMAIL_VERIFICATION">Pending Email Verification	</option>
              <option value="PENDING_PHONE_VERIFICATION">Pending Phone Verification	</option>
            </select>
          </form>
          <button onClick={openModal} className="btn-add-client">
            Add
          </button>
        </div>
      </div>

      <div className="tableau-list">
        <div className="container">
          <table>
            <thead>
              <tr>
                <th className="sort-header active" id='id' onClick={handleSelectedSort}>Customer ID</th>
                <th>Image</th>
                <th className="sort-header" id='name' onClick={handleSelectedSort}>Name</th>
                <th className="sort-header" id='phone' onClick={handleSelectedSort}>Phone</th>
                <th className="sort-header" id='email' onClick={handleSelectedSort}>Email</th>
                <th className="sort-header" id='created_at' onClick={handleSelectedSort}>Registration Date</th>
                <th className="sort-header" id='status' onClick={handleSelectedSort}>Status</th>
                <th className="sort-header" id='vip' onClick={handleSelectedSort}>VIP</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers?.length > 0 ? (
                customers.map((customer, index) => (
                  <tr key={customer.id}>
                    <td>{customer.id}</td>
                    <td>
                      <img
                        src={`${customer.media[0]?.original_url}`}
                        alt="Client image"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{customer.firstname} {customer.lastname}</td>
                    <td>{customer.phone}</td>
                    <td>{customer.email}</td>
                    <td>{castDate(customer.created_at || new Date())}</td>
                    <td className={customer?.status?.toLowerCase()}>
                      {asTitle(customer.status)}
                    </td>
                    <td>
                      {customer.vip ? "VIP" : "Not VIP"}
                      <AntSwitch
                        defaultChecked={customer.vip}
                        onChange={async (event) => {
                          try {
                            const response = await UserService.update(customer.id, { vip: event.target.checked }, axiosPrivate);
                            fetchCustomers();
                          } catch (error) {
                            console.error("Error updating vip:", error);
                          }
                        }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                    </td>
                    <td>
                      <div
                        className="edit"
                        onClick={() => setHoveredRow(prev => prev === index ? null : index)}
                        style={{ position: 'relative' }}
                        onMouseLeave={handleEditMouseLeave}
                        onMouseEnter={handleEditMouseEnter}
                      >
                        &#8942;
                        {hoveredRow === index && (
                          <div className="dropdown-menu">
                            <ul>
                              <li onClick={() => openModal(customer)}>Edit</li>
                              <li onClick={() => openDetailsModal(customer)}>
                                View Details
                              </li>
                              <li onClick={() => openHistoryModal(customer)}>
                                View Booking History
                              </li>
                              {customer.status !== 'BLOCKED' && (<li className="danger" onClick={() => blockUser(customer)}>Block Account</li>)}
                              {customer.status !== 'ACTIVE' && (<li className="success" onClick={() => unblockUser(customer)}>Activate Account</li>)}
                              <li className="danger"
                                onClick={() => handleDelete(customer.id)}
                              >
                                Delete
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No Client Found.</td>
                </tr>
              )}
            </tbody>
            <tfoot >
              <tr>
                <td colSpan={8}>
                  <div className="paginator">
                    <select value={perPage} onChange={handlePerPageChange} className="per-page">
                      <option value={1}>1</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={250}>250</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                    </select>
                    <div className="total">Total Records: {total}</div>
                    <div className="pagination">
                      <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                      <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                      <span>{currentPage} / {lastPage}</span>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                      <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Modal
        isOpen={detailsModalIsOpen}
        onRequestClose={closeDetailsModal}
        style={customStyles}
      >
        <button className="close-button" onClick={closeDetailsModal}>
          <span className="close-icon">✖</span>
        </button>
        {selectedCustomer && (
          <div className="form-inscription mrgBottom">
            <h2>Details for {selectedCustomer.extra.full_name}</h2><br />
            <form>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Customer ID:</label>
                <input type="text" name="id" value={selectedCustomer.id} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Full Name:</label>
                <input type="text" name="name" value={`${selectedCustomer.firstname} ${selectedCustomer.lastname}`} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Email:</label>
                <input type="text" name="email" value={selectedCustomer.email} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Phone:</label>
                <input type="text" name="phone" value={selectedCustomer.phone} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Registration Date:</label>
                <input type="text" name="registration_date" value={castDate(selectedCustomer.created_at || new Date())} readOnly />
              </div>
              <div className="input-hotel-container">
                <label className="input-hotel-label">Status:</label>
                <input type="text" name="status" value={asTitle(selectedCustomer.status)} readOnly />
              </div>
            </form>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={historyModalIsOpen}
        onRequestClose={closeHistoryModal}
        style={customStyles}
      >
        <button className="close-button" onClick={closeHistoryModal}>
          <span className="close-icon">✖</span>
        </button>
        <h2>Booking History for {selectedCustomer?.extra.full_name}</h2><br />

        {customerBookings && customerBookings.length > 0 ? (
          <ul>
            {customerBookings.map((booking) => (

              <div className="form-inscription">
                <form>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Booking ID:</label>
                    <input type="text" name="id" value={booking.id} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Description:</label>
                    <input type="text" name="name" value={booking.description || "-"} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Product:</label>
                    <input type="text" name="product" value={booking.product?.name || '-'} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Date:</label>
                    <input type="text" name="email" value={castDate(booking.date)} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Start Before:</label>
                    <input type="text" name="phone" value={castDate(booking.start_before)} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Duration: (Min)</label>
                    <input type="text" name="phone" value={booking.duration} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Registration Date:</label>
                    <input type="text" name="registration_date" value={castDate(selectedCustomer.created_at || new Date())} readOnly />
                  </div>
                  <div className="input-hotel-container">
                    <label className="input-hotel-label">Status:</label>
                    <input type="text" name="status" value={asTitle(booking.status)} readOnly />
                  </div>
                </form>
                <div className="separator-lines"></div>
              </div>

            ))}
          </ul>
        ) : (
          <p>No bookings found for this customer.</p>
        )}
      </Modal>


      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}

        style={customStyles}
      >
        <h2>Add a customer</h2>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            <div className="form-choix">
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MS"
                  id="madame"
                  checked={formData.civility === "MS"}
                  onChange={handleChange}
                />
                <label htmlFor="madame">Ms</label>
              </div>
              <div className="form-radio">
                <input
                  type="radio"
                  name="civility"
                  value="MR"
                  checked={formData.civility === "MR"}
                  id="monsieur"
                  onChange={handleChange}
                />
                <label htmlFor="monsieur">Mr</label>
              </div>
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Firstname</label>
                <input required value={formData.firstname} type="text" name="firstname" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>Lastname</label>
                <input required value={formData.lastname} type="text" name="lastname" onChange={handleChange} />
              </div>
            </div>

            <div className="form-text">
              <label>Phone</label>
              <PhoneInput
                defaultCountry="ch"
                type="tel"
                name="phone"
                value={formData.phone}
                disableCountryGuess="true"
                //hideDropdown="true"
                forceDialCode="true"
                //onChange={handlePhoneChange}
                onChange={(phone) => handlePhoneChange(phone)}
                required
              />
            </div>

            <div className="form-text">
              <label>Email Address</label>
              <input required value={formData.email} type="email" name="email" onChange={handleChange} />
            </div>

            <div className="form-text">
              <label>Birthdate</label>
              <input
                type="date"
                name="birthdate"
                value={formData.birthdate}
                onChange={handleChange}
                max={calculateMinDate()} // Date maximum permise (il y a 18 ans)
              />
            </div>
            <div className="flex-row gap-10">
              <div className="form-text">
                <label htmlFor="street">Select address</label>
                <Autocomplete
                  apiKey="AIzaSyCAbs8y3JhHOxPk3Bj0jAx1HfPHTCIc2Bk"
                  onPlaceSelected={handlePlaceSelect}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "CH" },
                  }}
                  style={{
                    height: "60px",
                    padding: "0 15px",
                    border: "0",
                    borderRadius: "20px",
                    boxShadow: "0px 8px 50px 0px rgba(0, 0, 0, 0.06)",
                  }}
                  defaultValue={
                    formData.street
                      ? `${formData.street_number} ${formData.street}, ${formData.city}, ${formData.state} ${formData.zip_code}`
                      : ""
                  }
                  className={`address-input ${isZipCodeVisible ? "compact" : ""}`}
                />
              </div>

              {isZipCodeVisible && (
                <div className="form-group zip-code-input">
                  <label>ZIP Code</label>
                  <input
                    type="text"
                    value={formData.zip_code}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        zip_code: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
              )}

              <div className="form-group icon-top-margin">
                {formData.zip_code && (
                  <img
                    src="assets/images/map-icon.svg"
                    alt="Map Icon"
                    className="clickable"
                    onClick={handleShowMap}
                  />
                )}
              </div>
            </div>

            {formData.latitude && showMap && (
              <Map
                coordinates={[coordinates]}
                mode="edit"
                containerStyle={{ width: '100%', height: '400px' }}
                mapOptions={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  mapTypeControl: false,
                  fullscreenControl: true,
                  streetViewControl: false,
                }}
                onCoordinateChange={handleCoordinateChange}
              />
            )}

            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>
            <button type="submit" className="btn-submit">
              {formData.id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Customer;
